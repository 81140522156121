import {
  desktopPublishing,
  proofreadingAndEditing,
  webContentTranslationAndLocalization,
  transcription,
} from './categories/caregories';
import { confidentiality } from './confidentiality';
import { cookiesPolicy } from './cookiesPolicy';
import { linguistsOutsourcing } from './linguistsOutsourcing';
import {
  englishLanguage,
  french,
  german,
  russian,
  turkish,
  Chinese,
  Italian,
  Spanish,
  Swedish,
  Ukrainian,
  Bulgarian,
  Portuguese,
  legalTranslation,
  literaryTranslationAndHumanities,
  militaryTranslation,
  medicalTranslation,
  religiousTranslation,
  leisureTourismAndHospitalityTranslation,
  economicAndFinancialTranslation,
  generalTranslation,
  simultaneousInterpretation,
  consecutiveInterpretation,
  whisperedInterpreting,
  eventAndConferenceEquipmentSupply,
  ourWorks,
} from './services/services';

export const rootData = [
  englishLanguage,
  french,
  german,
  russian,
  turkish,
  Chinese,
  Italian,
  Spanish,
  Swedish,
  Ukrainian,
  Bulgarian,
  Portuguese,
  legalTranslation,
  literaryTranslationAndHumanities,
  militaryTranslation,
  medicalTranslation,
  religiousTranslation,
  leisureTourismAndHospitalityTranslation,
  economicAndFinancialTranslation,
  generalTranslation,
  simultaneousInterpretation,
  consecutiveInterpretation,
  whisperedInterpreting,
  eventAndConferenceEquipmentSupply,
  ourWorks,
  desktopPublishing,
  proofreadingAndEditing,
  webContentTranslationAndLocalization,
  transcription,
  cookiesPolicy,
  confidentiality,
  linguistsOutsourcing,
];
