import { ReactElement } from 'react';
import './loading.scss';

const Loading = (): ReactElement => {
  return (
    <div className="loading-container">
      <div className="loading" />
    </div>
  );
};

export default Loading;
