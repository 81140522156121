import { t } from 'i18next';
import { ReactElement } from 'react';
import CustomButton, { ButtonType } from '../../components/Button/button';
import './underConstruction.scss';
import { useNavigate } from 'react-router-dom';
import UnderConstructionImage from '../../assets/img/under-construction-image';

const UnderConstruction = (): ReactElement => {
  let navigate = useNavigate();
  return (
    <div className="under-construction">
      <div className="under-construction__image">
        <UnderConstructionImage />
      </div>
      <span className="under-construction__text">{t('underConstruction')}</span>
      <CustomButton type={ButtonType.solid} onClick={() => navigate('/')}>
        {t('goHomePage')}
      </CustomButton>
    </div>
  );
};

export default UnderConstruction;
