import { ReactElement } from 'react';
import './under-construction-image.scss';

const UnderConstructionImage = (): ReactElement => {
  return (
    <svg
      className="animated"
      id="freepik_stories-under-construction"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 750 500"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="freepik--background-complete--inject-6"
        className="animable"
        style={{ transformOrigin: '375.355px 199.355px' }}
      >
        <path
          d="M604.05,69.68l5.89-9.49L607,58.39a2.67,2.67,0,0,1-1.23-2.5,13.25,13.25,0,0,0,.05-1.67,2.68,2.68,0,0,1,1.39-2.41l3-1.61L605,40.35,602,42a2.66,2.66,0,0,1-2.77-.18c-.46-.32-.94-.61-1.42-.88a2.64,2.64,0,0,1-1.4-2.41l.11-3.42-11.17-.34-.1,3.43a2.67,2.67,0,0,1-1.56,2.31c-.24.11-.49.24-.73.37s-.48.26-.72.41a2.68,2.68,0,0,1-2.77,0l-2.93-1.82L570.62,49l2.91,1.81a2.66,2.66,0,0,1,1.23,2.5,15.35,15.35,0,0,0-.06,1.66,2.66,2.66,0,0,1-1.38,2.41l-3,1.62,5.28,9.84,3-1.61a2.64,2.64,0,0,1,2.77.17,16,16,0,0,0,1.42.88,2.66,2.66,0,0,1,1.4,2.41l-.11,3.42,11.16.35.11-3.43a2.66,2.66,0,0,1,1.55-2.3c.25-.12.5-.24.74-.38l.72-.4a2.67,2.67,0,0,1,2.79,0Zm-9.45-7.05a9.14,9.14,0,1,1,3.75-12.38A9.14,9.14,0,0,1,594.6,62.63Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '590.265px 54.615px',
          }}
          id="elc346592n2g"
          className="animable"
        />
        <path
          d="M127,332.79,123.07,326l-2.07,1.2a1.85,1.85,0,0,1-2-.07,10.14,10.14,0,0,0-1-.58,1.86,1.86,0,0,1-1-1.66v-2.39h-7.83v2.39a1.86,1.86,0,0,1-1,1.66,10.14,10.14,0,0,0-1,.58,1.85,1.85,0,0,1-2,.07l-2.07-1.2-3.91,6.78,2.08,1.2a1.85,1.85,0,0,1,.91,1.72c0,.19,0,.39,0,.58s0,.38,0,.57a1.84,1.84,0,0,1-.91,1.72l-2.08,1.21,3.91,6.78,2.08-1.2a1.85,1.85,0,0,1,2,.07c.32.21.66.4,1,.58a1.85,1.85,0,0,1,1,1.65v2.4H117v-2.4a1.85,1.85,0,0,1,1-1.65c.34-.18.68-.37,1-.58a1.85,1.85,0,0,1,1.95-.07l2.08,1.2,3.91-6.78-2.08-1.2a1.86,1.86,0,0,1-.9-1.72c0-.19,0-.39,0-.58s0-.39,0-.58a1.85,1.85,0,0,1,.91-1.72Zm-7.48,3.5a6.41,6.41,0,1,1-6.4-6.41A6.39,6.39,0,0,1,119.5,336.29Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '113.095px 336.28px',
          }}
          id="el2dzu8lkq33o"
          className="animable"
        />
        <path
          d="M95.18,233.09a30.47,30.47,0,1,0,30.47,30.47A30.46,30.46,0,0,0,95.18,233.09Zm0,44.69a14.23,14.23,0,1,1,14.23-14.22A14.22,14.22,0,0,1,95.18,277.78Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '95.18px 263.56px',
          }}
          id="elpxl3nayva6"
          className="animable"
        />
        <polygon
          points="101.04 234.35 89.32 234.35 89.97 226.06 100.39 226.06 101.04 234.35"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '95.18px 230.205px',
          }}
          id="elgkq27yokb9c"
          className="animable"
        />
        <polygon
          points="78.73 238.3 70.45 246.58 65.04 240.26 72.41 232.89 78.73 238.3"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '71.885px 239.735px',
          }}
          id="el95rdj2x16jm"
          className="animable"
        />
        <polygon
          points="65.76 256.86 65.76 268.58 57.46 267.93 57.46 257.51 65.76 256.86"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '61.61px 262.72px',
          }}
          id="el64b8v0gorj3"
          className="animable"
        />
        <polygon
          points="69.7 279.16 77.99 287.45 71.67 292.86 64.3 285.49 69.7 279.16"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '71.145px 286.01px',
          }}
          id="elzchicd7dnqg"
          className="animable"
        />
        <polygon
          points="88.27 292.14 99.98 292.14 99.34 300.44 88.92 300.44 88.27 292.14"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '94.125px 296.29px',
          }}
          id="elaf1jxk32yfa"
          className="animable"
        />
        <polygon
          points="110.57 288.19 118.86 279.91 124.26 286.23 116.89 293.6 110.57 288.19"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '117.415px 286.755px',
          }}
          id="el9l9a2c2jyk5"
          className="animable"
        />
        <polygon
          points="123.55 269.63 123.55 257.91 131.84 258.56 131.84 268.99 123.55 269.63"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '127.695px 263.77px',
          }}
          id="ellayjemdtioi"
          className="animable"
        />
        <polygon
          points="119.6 247.33 111.32 239.04 117.64 233.64 125.01 241 119.6 247.33"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '118.165px 240.485px',
          }}
          id="elqny52j6fkkk"
          className="animable"
        />
        <path
          d="M655.87,82.83a30.48,30.48,0,1,0,30.48,30.48A30.47,30.47,0,0,0,655.87,82.83Zm0,44.7a14.23,14.23,0,1,1,14.24-14.22A14.22,14.22,0,0,1,655.87,127.53Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '655.87px 113.31px',
          }}
          id="elyj0jboupbo"
          className="animable"
        />
        <polygon
          points="661.73 84.1 650.01 84.1 650.66 75.8 661.09 75.8 661.73 84.1"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '655.87px 79.95px',
          }}
          id="elhuc7kgl128t"
          className="animable"
        />
        <polygon
          points="639.43 88.05 631.14 96.33 625.74 90.01 633.11 82.64 639.43 88.05"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '632.585px 89.485px',
          }}
          id="elb6jjd13pnot"
          className="animable"
        />
        <polygon
          points="626.45 106.61 626.45 118.33 618.16 117.68 618.16 107.26 626.45 106.61"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '622.305px 112.47px',
          }}
          id="elyfvdicng5m"
          className="animable"
        />
        <polygon
          points="630.4 128.91 638.68 137.2 632.36 142.6 624.99 135.24 630.4 128.91"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '631.835px 135.755px',
          }}
          id="elicvo9skjv3g"
          className="animable"
        />
        <polygon
          points="648.96 141.89 660.68 141.89 660.03 150.19 649.61 150.19 648.96 141.89"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '654.82px 146.04px',
          }}
          id="eld3ji3lal68"
          className="animable"
        />
        <polygon
          points="671.26 137.94 679.55 129.66 684.96 135.98 677.59 143.35 671.26 137.94"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '678.11px 136.505px',
          }}
          id="el7t3l9tmijp8"
          className="animable"
        />
        <polygon
          points="684.24 119.38 684.24 107.66 692.54 108.31 692.54 118.73 684.24 119.38"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '688.39px 113.52px',
          }}
          id="elzkqaeaszgn"
          className="animable"
        />
        <polygon
          points="680.3 97.08 672.01 88.79 678.33 83.38 685.7 90.75 680.3 97.08"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '678.855px 90.23px',
          }}
          id="elb77fa5768pk"
          className="animable"
        />
        <polygon
          points="575.63 292.18 574.64 298.22 573.59 304.71 563.93 363.9 548.15 363.9 557.8 304.71 558.86 298.22 559.84 292.18 575.63 292.18"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '561.89px 328.04px',
          }}
          id="el8vo1rzrjh3x"
          className="animable"
        />
        <path
          d="M573.34,292.18c-.83,6-1.73,12-2.63,18l-2.78,17.94L565,346,563.5,355l-1.56,8.94,1.28-9,1.35-9L567.35,328l2.92-17.92C571.26,304.11,572.27,298.14,573.34,292.18Z"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '567.64px 328.06px',
          }}
          id="elzna4pwvw2q"
          className="animable"
        />
        <polygon
          points="574.64 298.22 573.59 304.71 557.8 304.71 558.86 298.22 574.64 298.22"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '566.22px 301.465px',
          }}
          id="el7qubs7gdgiw"
          className="animable"
        />
        <polygon
          points="653.56 292.18 654.54 298.22 655.6 304.71 665.25 363.9 681.04 363.9 671.39 304.71 670.33 298.22 669.35 292.18 653.56 292.18"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '667.3px 328.04px',
          }}
          id="elgtu2pqrp9m"
          className="animable"
        />
        <path
          d="M655.9,292.18c.83,6,1.72,12,2.63,18l2.78,17.94L664.23,346l1.5,8.95,1.57,8.94-1.28-9-1.35-9L661.89,328,659,310.07C658,304.11,657,298.14,655.9,292.18Z"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '661.6px 328.035px',
          }}
          id="eld0094dp32rq"
          className="animable"
        />
        <polygon
          points="654.54 298.22 670.33 298.22 671.39 304.71 655.6 304.71 654.54 298.22"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '662.965px 301.465px',
          }}
          id="el9vhoimgxbep"
          className="animable"
        />
        <polygon
          points="575.05 275.53 562.53 275.53 564.09 263.86 573.5 263.86 575.05 275.53"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '568.79px 269.695px',
          }}
          id="elemb4mai3fil"
          className="animable"
        />
        <path
          d="M580.46,255.52a11.67,11.67,0,1,1-11.67-11.66A11.66,11.66,0,0,1,580.46,255.52Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '568.79px 255.53px',
          }}
          id="elog2qcaeebyd"
          className="animable"
        />
        <path
          d="M577.66,255.52a8.87,8.87,0,1,1-8.87-8.86A8.87,8.87,0,0,1,577.66,255.52Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '568.79px 255.53px',
          }}
          id="elqwolfytm6lo"
          className="animable"
        />
        <path
          d="M576.21,255a7.67,7.67,0,0,0-2.9-5.44,7.82,7.82,0,0,0-6-1.56,7.48,7.48,0,0,0-5.13,3.33,7.85,7.85,0,0,0-.94,6.4,7.38,7.38,0,0,0,3.47,4.38,7.7,7.7,0,0,0,5.42.83,8,8,0,0,0,4.56-2.78,8.4,8.4,0,0,0,1.87-4.94c0-.09,0-.16-.07-.16s-.08.06-.08.15a7.81,7.81,0,0,1-.42,2.14,8.28,8.28,0,0,1-1,1.92,8.78,8.78,0,0,1-1.45,1.6,9.51,9.51,0,0,1-1.84,1.19,7.42,7.42,0,0,1-6,.14,7.28,7.28,0,0,1-4.11-4.5,7.5,7.5,0,0,1,.78-6.21,7.15,7.15,0,0,1,5-3.28A7.44,7.44,0,0,1,575.7,253a8.94,8.94,0,0,1,.47,2.06s0,0,0,0S576.21,255,576.21,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '568.748px 255.51px',
          }}
          id="elkuhaax7tlh8"
          className="animable"
        />
        <path
          d="M575.17,255a6.66,6.66,0,0,0-2.52-4.65,6.82,6.82,0,0,0-5.09-1.35,6.46,6.46,0,0,0-4.44,2.83,6.81,6.81,0,0,0-.86,5.54,6.29,6.29,0,0,0,3,3.83,6.66,6.66,0,0,0,4.73.71,7,7,0,0,0,3.94-2.44,7.36,7.36,0,0,0,1.63-4.29c0-.07,0-.13-.08-.13s-.08.05-.08.13a7.08,7.08,0,0,1-4,5.91,6.38,6.38,0,0,1-5.24.15,6.2,6.2,0,0,1-3.54-3.91,6.43,6.43,0,0,1,.71-5.35,6.12,6.12,0,0,1,4.3-2.77,6.43,6.43,0,0,1,7.12,4.06,7.93,7.93,0,0,1,.42,1.76s0,0,0,0A.07.07,0,0,0,575.17,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '568.786px 255.502px',
          }}
          id="eln37ipou5tsk"
          className="animable"
        />
        <path
          d="M574.13,255a5.62,5.62,0,0,0-2.13-3.85,5.77,5.77,0,0,0-4.23-1.14,5.37,5.37,0,0,0-3.74,2.32,5.73,5.73,0,0,0-.79,4.68,5.29,5.29,0,0,0,2.55,3.28,5.67,5.67,0,0,0,4,.59,5.94,5.94,0,0,0,3.33-2.1,6.39,6.39,0,0,0,1.37-3.63c0-.06,0-.11-.07-.11s-.08,0-.09.11a6,6,0,0,1-3.41,5,5.33,5.33,0,0,1-4.42.16,5.23,5.23,0,0,1-3-3.31,5.45,5.45,0,0,1,.64-4.5,5.09,5.09,0,0,1,3.61-2.25,6.1,6.1,0,0,1,2.86.34A5.35,5.35,0,0,1,573,252.3a6,6,0,0,1,.73,1.29,6.94,6.94,0,0,1,.37,1.46l0,0A.06.06,0,0,0,574.13,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '568.769px 255.489px',
          }}
          id="el8qidxuqhm1"
          className="animable"
        />
        <path
          d="M573.09,255a4.6,4.6,0,0,0-1.75-3.05,4.78,4.78,0,0,0-3.35-.93,4.38,4.38,0,0,0-3.06,1.82,4.72,4.72,0,0,0-.71,3.83,4.29,4.29,0,0,0,2.09,2.73,4.63,4.63,0,0,0,3.35.45,4.92,4.92,0,0,0,2.72-1.75,5.34,5.34,0,0,0,1.11-3,.08.08,0,0,0-.07-.09.1.1,0,0,0-.09.09,5,5,0,0,1-2.77,4,4.31,4.31,0,0,1-3.6.17,4.17,4.17,0,0,1-2.43-2.72,4.38,4.38,0,0,1,.57-3.63,4.06,4.06,0,0,1,2.92-1.75,5.28,5.28,0,0,1,2.25.29,4.37,4.37,0,0,1,1.86,1.32,4.93,4.93,0,0,1,.59,1,5.37,5.37,0,0,1,.33,1.16l0,0S573.1,255.05,573.09,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '568.787px 255.489px',
          }}
          id="elxsjixr77nh"
          className="animable"
        />
        <polygon
          points="666.38 275.53 653.86 275.53 655.42 263.86 664.83 263.86 666.38 275.53"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '660.12px 269.695px',
          }}
          id="elzxbb41l2rha"
          className="animable"
        />
        <path
          d="M671.79,255.52a11.67,11.67,0,1,1-11.67-11.66A11.67,11.67,0,0,1,671.79,255.52Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '660.12px 255.53px',
          }}
          id="elo9lahbrg6bf"
          className="animable"
        />
        <path
          d="M669,255.52a8.87,8.87,0,1,1-8.87-8.86A8.87,8.87,0,0,1,669,255.52Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '660.13px 255.53px',
          }}
          id="elkk5jnlmmhhr"
          className="animable"
        />
        <path
          d="M667.53,255a7.62,7.62,0,0,0-2.89-5.44,7.82,7.82,0,0,0-6-1.56,7.48,7.48,0,0,0-5.13,3.33,7.85,7.85,0,0,0-.94,6.4,7.38,7.38,0,0,0,3.47,4.38,7.7,7.7,0,0,0,5.42.83,8,8,0,0,0,4.56-2.78,8.4,8.4,0,0,0,1.87-4.94c0-.09,0-.16-.07-.16s-.08.06-.08.15a7.81,7.81,0,0,1-.42,2.14,8.69,8.69,0,0,1-1,1.92,8.78,8.78,0,0,1-1.45,1.6,9.51,9.51,0,0,1-1.84,1.19,7.42,7.42,0,0,1-6.05.14,7.28,7.28,0,0,1-4.11-4.5,7.5,7.5,0,0,1,.78-6.21,7.13,7.13,0,0,1,5-3.28A7.44,7.44,0,0,1,667,253a8.94,8.94,0,0,1,.47,2.06s0,0,0,0A.06.06,0,0,0,667.53,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '660.078px 255.51px',
          }}
          id="eld4pt9ffp2bg"
          className="animable"
        />
        <path
          d="M666.5,255a6.66,6.66,0,0,0-2.52-4.65,6.82,6.82,0,0,0-5.09-1.35,6.46,6.46,0,0,0-4.44,2.83,6.81,6.81,0,0,0-.86,5.54,6.29,6.29,0,0,0,3,3.83,6.66,6.66,0,0,0,4.73.71,7,7,0,0,0,3.94-2.44,7.28,7.28,0,0,0,1.62-4.29c0-.07,0-.13-.07-.13s-.08.05-.08.13a7.08,7.08,0,0,1-4,5.91,6.38,6.38,0,0,1-5.24.15,6.2,6.2,0,0,1-3.54-3.91,6.46,6.46,0,0,1,.7-5.35,6.16,6.16,0,0,1,4.3-2.77,6.43,6.43,0,0,1,7.13,4.06,7.93,7.93,0,0,1,.42,1.76s0,0,0,0A.07.07,0,0,0,666.5,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '660.111px 255.502px',
          }}
          id="elgocahbo1b"
          className="animable"
        />
        <path
          d="M665.46,255a5.62,5.62,0,0,0-2.13-3.85A5.77,5.77,0,0,0,659.1,250a5.37,5.37,0,0,0-3.74,2.32,5.73,5.73,0,0,0-.79,4.68,5.29,5.29,0,0,0,2.55,3.28,5.67,5.67,0,0,0,4,.59,5.94,5.94,0,0,0,3.33-2.1,6.39,6.39,0,0,0,1.37-3.63c0-.06,0-.11-.07-.11s-.08,0-.09.11a6.06,6.06,0,0,1-3.41,5,5.33,5.33,0,0,1-4.42.16,5.21,5.21,0,0,1-3-3.31,5.45,5.45,0,0,1,.64-4.5,5.09,5.09,0,0,1,3.61-2.25,6.1,6.1,0,0,1,2.86.34,5.4,5.4,0,0,1,2.33,1.71,6,6,0,0,1,.73,1.29,6.94,6.94,0,0,1,.37,1.46l0,0A.06.06,0,0,0,665.46,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '660.099px 255.48px',
          }}
          id="el88b14sk9y3b"
          className="animable"
        />
        <path
          d="M664.42,255a4.6,4.6,0,0,0-1.75-3.05,4.78,4.78,0,0,0-3.35-.93,4.38,4.38,0,0,0-3.06,1.82,4.72,4.72,0,0,0-.71,3.83,4.29,4.29,0,0,0,2.09,2.73,4.62,4.62,0,0,0,3.35.45,4.92,4.92,0,0,0,2.72-1.75,5.41,5.41,0,0,0,1.11-3,.08.08,0,0,0-.07-.09.1.1,0,0,0-.09.09,5,5,0,0,1-2.77,4,4.31,4.31,0,0,1-3.6.17,4.17,4.17,0,0,1-2.43-2.72,4.38,4.38,0,0,1,.57-3.63,4.06,4.06,0,0,1,2.92-1.75,5.28,5.28,0,0,1,2.25.29,4.28,4.28,0,0,1,1.85,1.32,4.55,4.55,0,0,1,.6,1,5.37,5.37,0,0,1,.33,1.16l0,0S664.42,255.05,664.42,255Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '660.117px 255.489px',
          }}
          id="elhesznusya56"
          className="animable"
        />
        <rect
          x="535.95"
          y="269.58"
          width="157.3"
          height="30.09"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '614.6px 284.625px',
          }}
          id="el7i4qjsea5wk"
          className="animable"
        />
        <polygon
          points="568.27 269.58 556.86 299.67 536.54 299.67 547.96 269.58 568.27 269.58"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '552.405px 284.625px',
          }}
          id="el2ukelg3n9yc"
          className="animable"
        />
        <polygon
          points="609.91 269.58 598.5 299.67 578.18 299.67 589.59 269.58 609.91 269.58"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '594.045px 284.625px',
          }}
          id="el2icsigwma0q"
          className="animable"
        />
        <polygon
          points="651.55 269.58 640.13 299.67 619.82 299.67 631.24 269.58 651.55 269.58"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '635.685px 284.625px',
          }}
          id="el49o3g02iqko"
          className="animable"
        />
        <polygon
          points="693.19 269.58 681.77 299.67 661.45 299.67 672.87 269.58 693.19 269.58"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '677.32px 284.625px',
          }}
          id="el9h4wwq174kb"
          className="animable"
        />
        <rect
          x="539.13"
          y="327.48"
          width="150.93"
          height="19.04"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '614.595px 337px',
          }}
          id="eleh2nbbwmhlh"
          className="animable"
        />
        <polygon
          points="570.15 327.48 559.2 346.52 539.71 346.52 550.66 327.48 570.15 327.48"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '554.93px 337px',
          }}
          id="el4ai9ozjpoe9"
          className="animable"
        />
        <polygon
          points="610.1 327.48 599.15 346.52 579.65 346.52 590.61 327.48 610.1 327.48"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '594.875px 337px',
          }}
          id="elh7kfnw40xc"
          className="animable"
        />
        <polygon
          points="650.05 327.48 639.1 346.52 619.61 346.52 630.56 327.48 650.05 327.48"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '634.83px 337px',
          }}
          id="elp48rvbcqe1"
          className="animable"
        />
        <polygon
          points="690.01 327.48 679.05 346.52 659.55 346.52 670.51 327.48 690.01 327.48"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '674.78px 337px',
          }}
          id="elmk0r1a86njf"
          className="animable"
        />
      </g>
      <g
        id="freepik--Shadow--inject-6"
        className="animable"
        style={{ transformOrigin: '492.62px 445.33px' }}
      >
        <ellipse
          cx="492.62"
          cy="445.33"
          rx="182.16"
          ry="12.3"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '492.62px 445.33px',
          }}
          id="elj5hokiewzzj"
          className="animable"
        />
      </g>
      <g
        id="freepik--Floor--inject-6"
        className="animable"
        style={{ transformOrigin: '375px 363.885px' }}
      >
        <polygon
          points="56.76 363.88 136.32 363.64 215.88 363.55 375 363.39 534.12 363.55 613.68 363.64 693.24 363.88 613.68 364.13 534.12 364.22 375 364.38 215.88 364.22 136.32 364.13 56.76 363.88"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '375px 363.885px',
          }}
          id="elqgououxma3h"
          className="animable"
        />
      </g>
      <g
        id="freepik--Device--inject-6"
        className="animable"
        style={{ transformOrigin: '375px 256.8px' }}
      >
        <rect
          x="182.97"
          y="109.9"
          width="384.06"
          height="256.91"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '375px 238.355px',
          }}
          id="elukri7btps6d"
          className="animable"
        />
        <rect
          x="185.76"
          y="113.04"
          width="378.48"
          height="250.64"
          style={{ fill: 'rgb(38, 50, 56)', transformOrigin: '375px 238.36px' }}
          id="elhg4hkfekvso"
          className="animable"
        />
        <rect
          x="132.95"
          y="386.07"
          width="484.1"
          height="17.63"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '375px 394.885px',
          }}
          id="elsw1uycti8nd"
          className="animable"
        />
        <polygon
          points="132.95 386.07 182.97 366.81 567.03 366.81 617.05 386.07 132.95 386.07"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '375px 376.44px',
          }}
          id="elt32emkpksks"
          className="animable"
        />
        <polygon
          points="187.44 379.24 219.72 366.81 530.28 366.81 562.56 379.24 187.44 379.24"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '375px 373.025px',
          }}
          id="eld09wd5z17iq"
          className="animable"
        />
        <rect
          x="206.87"
          y="131.99"
          width="336.27"
          height="212.73"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '375.005px 238.355px',
          }}
          id="el7mzh2zef9et"
          className="animable"
        />
        <rect
          x="206.87"
          y="131.99"
          width="336.27"
          height="8.19"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '375.005px 136.085px',
          }}
          id="elvj3opw834i"
          className="animable"
        />
        <path
          d="M213.37,136.08a2,2,0,1,1-2-2A2,2,0,0,1,213.37,136.08Z"
          style={{ fill: '#F1923D', transformOrigin: '211.37px 136.08px' }}
          id="elj0kyjbptwen"
          className="animable"
        />
        <path
          d="M221.66,136.08a2,2,0,1,1-2-2A2,2,0,0,1,221.66,136.08Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '219.66px 136.08px',
          }}
          id="elayes5ki53ok"
          className="animable"
        />
        <path
          d="M230,136.08a2,2,0,1,1-2-2A2,2,0,0,1,230,136.08Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '228px 136.08px',
          }}
          id="el00g10by0ge1pn"
          className="animable"
        />
        <g id="el6pexcxtm7wg">
          <rect
            x="367.66"
            y="231.94"
            width="61.27"
            height="47.01"
            style={{
              fill: 'rgb(235, 235, 235)',
              transformOrigin: '398.295px 255.445px',
              transform: 'rotate(180deg)',
            }}
            className="animable"
            id="el5qcqzub19ui"
          />
        </g>
        <path
          d="M373.76,242.22a5.57,5.57,0,1,0,4.37-4.37A5.57,5.57,0,0,0,373.76,242.22Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '379.222px 243.312px',
          }}
          id="el44g412lqyxh"
          className="animable"
        />
        <polygon
          points="387.88 268.82 428.93 268.82 408.4 243.52 387.88 268.82"
          style={{
            fill: 'rgb(199, 199, 199)',
            transformOrigin: '408.405px 256.17px',
          }}
          id="elngwfbx2m8h"
          className="animable"
        />
        <polygon
          points="367.66 268.84 404.07 268.84 385.87 252.36 367.66 268.84"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '385.865px 260.6px',
          }}
          id="elpm0b4x8gtw"
          className="animable"
        />
        <rect
          x="367.66"
          y="268.82"
          width="61.27"
          height="10.13"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '398.295px 273.885px',
          }}
          id="elq64dnqno02s"
          className="animable"
        />
        <rect
          x="223.47"
          y="231.94"
          width="46.02"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '246.48px 233.875px',
          }}
          id="elp46ceq3ck1"
          className="animable"
        />
        <rect
          x="223.47"
          y="240.92"
          width="133.18"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '290.06px 242.855px',
          }}
          id="el7tx3qlzm52p"
          className="animable"
        />
        <rect
          x="223.47"
          y="249.44"
          width="133.18"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '290.06px 251.375px',
          }}
          id="eleycd3kan3mn"
          className="animable"
        />
        <rect
          x="223.47"
          y="257.96"
          width="133.18"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '290.06px 259.895px',
          }}
          id="elprpmg1y6tk"
          className="animable"
        />
        <rect
          x="223.47"
          y="266.48"
          width="133.18"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '290.06px 268.415px',
          }}
          id="elpykjyuuson"
          className="animable"
        />
        <rect
          x="223.47"
          y="274.99"
          width="133.18"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '290.06px 276.925px',
          }}
          id="el1sefxtuasah"
          className="animable"
        />
        <rect
          x="334.38"
          y="155.93"
          width="46.02"
          height="10.36"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '357.39px 161.11px',
          }}
          id="el90vuvtxxt3"
          className="animable"
        />
        <rect
          x="334.38"
          y="173.43"
          width="94.55"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '381.655px 175.365px',
          }}
          id="el1236j7fcn8i"
          className="animable"
        />
        <rect
          x="334.38"
          y="181.95"
          width="94.55"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '381.655px 183.885px',
          }}
          id="elifp9i6zxo8"
          className="animable"
        />
        <rect
          x="334.38"
          y="190.47"
          width="94.55"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '381.655px 192.405px',
          }}
          id="el2dbitwhwpwv"
          className="animable"
        />
        <rect
          x="334.38"
          y="198.99"
          width="94.55"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '381.655px 200.925px',
          }}
          id="eleegznnm1gnv"
          className="animable"
        />
        <rect
          x="334.38"
          y="210.32"
          width="94.55"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '381.655px 212.255px',
          }}
          id="el4bq9bd5cb9s"
          className="animable"
        />
        <rect
          x="334.38"
          y="218.84"
          width="94.55"
          height="3.87"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '381.655px 220.775px',
          }}
          id="elp3l4ygvlyc"
          className="animable"
        />
        <rect
          x="442.36"
          y="155.93"
          width="84.16"
          height="10.54"
          style={{ fill: '#F1923D', transformOrigin: '484.44px 161.2px' }}
          id="el4kp1nebz24u"
          className="animable"
        />
        <rect
          x="442.59"
          y="166.56"
          width="83.71"
          height="165.33"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '484.445px 249.225px',
          }}
          id="elvjdqpj98sgl"
          className="animable"
        />
        <path
          d="M526.53,332.16H442.36V166.29h84.17Zm-83.71-.54h83.24V166.83H442.82Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '484.445px 249.225px',
          }}
          id="el3evpsd0t4jr"
          className="animable"
        />
        <polygon
          points="442.81 331.99 442.37 331.79 526.08 166.46 526.51 166.66 442.81 331.99"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '484.44px 249.225px',
          }}
          id="elrpzdml6xri7"
          className="animable"
        />
        <polygon
          points="526.08 331.99 442.37 166.66 442.81 166.46 526.51 331.79 526.08 331.99"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '484.44px 249.225px',
          }}
          id="eln8glz1jpp6i"
          className="animable"
        />
        <rect
          x="223.65"
          y="156.11"
          width="100.3"
          height="66.41"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '273.8px 189.315px',
          }}
          id="el88qem4ed7z"
          className="animable"
        />
        <path
          d="M223.49,222.71v-.17l0-66.58h.17l100.46,0,0,66.75H324Zm.32-66.42,0,66.08,100,0,0-66.07Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '273.805px 189.335px',
          }}
          id="elm7m57zop5zt"
          className="animable"
        />
        <path
          d="M223.81,156.29c8.43,5.35,16.82,10.78,25.19,16.22l25.05,16.42L299,205.53l12.43,8.36,12.37,8.45-12.63-8.06-12.56-8.15-25.06-16.42L248.61,173.1C240.32,167.54,232,162,223.81,156.29Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '273.805px 189.315px',
          }}
          id="el2wwnaafreqi"
          className="animable"
        />
        <path
          d="M323.79,156.29c-8.23,5.67-16.51,11.25-24.8,16.81l-24.94,16.61L249,206.13l-12.57,8.15-12.62,8.06,12.37-8.45,12.42-8.36,24.94-16.6,25.06-16.42C307,167.07,315.35,161.64,323.79,156.29Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '273.8px 189.315px',
          }}
          id="elbhgnqmnce9e"
          className="animable"
        />
        <g id="el2lzqgk9a3i5">
          <rect
            x="302.79"
            y="206.36"
            width="46.7"
            height="204.65"
            style={{
              fill: 'rgb(255, 255, 255)',
              transformOrigin: '326.14px 308.685px',
              transform: 'rotate(-90deg)',
            }}
            className="animable"
            id="el0nzxz7qe8jc"
          />
        </g>
        <path
          d="M428.8,285.2v47H223.47v-47Zm-.68,46.7V285.46h-204V331.9Z"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '326.135px 308.7px',
          }}
          id="eli0rph6sg5zq"
          className="animable"
        />
        <polygon
          points="428.59 331.91 428.34 332.15 223.68 285.45 223.93 285.21 428.59 331.91"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '326.135px 308.68px',
          }}
          id="elofo0hf3b8m"
          className="animable"
        />
        <polygon
          points="428.59 285.45 223.93 332.15 223.68 331.91 428.34 285.21 428.59 285.45"
          style={{
            fill: 'rgb(219, 219, 219)',
            transformOrigin: '326.135px 308.68px',
          }}
          id="elq2ypfcsm0y"
          className="animable"
        />
      </g>
      <g
        id="freepik--Crane--inject-6"
        className="animable"
        style={{ transformOrigin: '243.43px 233.04px' }}
      >
        <g id="el21vfehwhu37">
          <rect
            x="116.29"
            y="126.25"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 127.765px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="elt2ogqkzbn6"
          />
        </g>
        <rect
          x="121.18"
          y="138.53"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 140.045px',
          }}
          id="elvgyi5py5nxi"
          className="animable"
        />
        <rect
          x="121.18"
          y="114.92"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 116.435px',
          }}
          id="eli0oq9n1h2d"
          className="animable"
        />
        <g id="elzqx924v031">
          <rect
            x="130.34"
            y="112.2"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 127.77px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elvc0n2l245ce"
          />
        </g>
        <g id="elv17m6qcb20p">
          <rect
            x="116.29"
            y="149.86"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 151.375px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="el873gs7opted"
          />
        </g>
        <rect
          x="121.18"
          y="162.14"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 163.655px',
          }}
          id="el27wsfykt7w"
          className="animable"
        />
        <rect
          x="121.18"
          y="138.53"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 140.045px',
          }}
          id="elwctpp7bkq7"
          className="animable"
        />
        <g id="elt9eyupdfv1k">
          <rect
            x="130.34"
            y="135.81"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 151.38px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="eljgu0bc9q7nq"
          />
        </g>
        <g id="elhjzfuj44mzr">
          <rect
            x="116.29"
            y="173.47"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 174.985px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="elkmbaacty8u9"
          />
        </g>
        <rect
          x="121.18"
          y="185.75"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 187.265px',
          }}
          id="elewgrirgcnle"
          className="animable"
        />
        <rect
          x="121.18"
          y="162.14"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 163.655px',
          }}
          id="el7y1zyylmz8b"
          className="animable"
        />
        <g id="elnl648y7vwb">
          <rect
            x="130.34"
            y="159.42"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 174.99px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elva4l8x6gxi"
          />
        </g>
        <g id="elujt0h00ssrm">
          <rect
            x="116.29"
            y="197.08"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 198.595px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="eluf3mgenax57"
          />
        </g>
        <rect
          x="121.18"
          y="209.36"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 210.875px',
          }}
          id="elmve055fcdtf"
          className="animable"
        />
        <rect
          x="121.18"
          y="185.75"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 187.265px',
          }}
          id="elgvutiu7csx8"
          className="animable"
        />
        <g id="eleherwo59aw4">
          <rect
            x="130.34"
            y="183.03"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 198.6px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elhoeq5j9lrgw"
          />
        </g>
        <g id="elndznazovzrk">
          <rect
            x="116.29"
            y="220.69"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 222.205px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="elac9gqcn4la8"
          />
        </g>
        <rect
          x="121.18"
          y="232.97"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 234.485px',
          }}
          id="eln567clker9"
          className="animable"
        />
        <rect
          x="121.18"
          y="209.36"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 210.875px',
          }}
          id="elubmf5v805pc"
          className="animable"
        />
        <g id="ela3x9xvwvbfe">
          <rect
            x="130.34"
            y="206.63"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 222.2px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elthzbwou9ru"
          />
        </g>
        <g id="eluzofvn89bvd">
          <rect
            x="116.29"
            y="244.3"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 245.815px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="el4inub8k6juw"
          />
        </g>
        <rect
          x="121.18"
          y="256.57"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 258.085px',
          }}
          id="elt16nudmo57"
          className="animable"
        />
        <rect
          x="121.18"
          y="232.97"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 234.485px',
          }}
          id="elxte02h9qdma"
          className="animable"
        />
        <g id="el09vpajgigsrp">
          <rect
            x="130.34"
            y="230.24"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 245.81px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elv05f57fqc7e"
          />
        </g>
        <g id="elmondze7usah">
          <rect
            x="116.29"
            y="267.9"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 269.415px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="eljytsmay3xd8"
          />
        </g>
        <rect
          x="121.18"
          y="280.18"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 281.695px',
          }}
          id="eluuf512zlhkr"
          className="animable"
        />
        <rect
          x="121.18"
          y="256.57"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 258.085px',
          }}
          id="elb85pt8xr5aw"
          className="animable"
        />
        <g id="elwlt2oerppmi">
          <rect
            x="130.34"
            y="253.85"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 269.42px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="el3umvwmk9vnv"
          />
        </g>
        <g id="elohdxjwvg9go">
          <rect
            x="116.29"
            y="291.51"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 293.025px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="el6w2cqgs05ib"
          />
        </g>
        <rect
          x="121.18"
          y="303.79"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 305.305px',
          }}
          id="el4u8wdwjmbt3"
          className="animable"
        />
        <rect
          x="121.18"
          y="280.18"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 281.695px',
          }}
          id="elijf2eaa9af"
          className="animable"
        />
        <g id="el183ozxjys8a">
          <rect
            x="130.34"
            y="277.46"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 293.03px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="eloigxfnvkd2"
          />
        </g>
        <g id="el8tkyrekqvdt">
          <rect
            x="116.29"
            y="315.12"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 316.635px',
              transform: 'rotate(-46.68deg)',
            }}
            className="animable"
            id="ellcvlp3sv62"
          />
        </g>
        <rect
          x="121.18"
          y="327.4"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 328.915px',
          }}
          id="elc0yijnfxeb"
          className="animable"
        />
        <rect
          x="121.18"
          y="303.79"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 305.305px',
          }}
          id="elkwfi1rbux7i"
          className="animable"
        />
        <g id="elpzzt9juo9ui">
          <rect
            x="130.34"
            y="301.07"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 316.64px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elx5cmx38b2q"
          />
        </g>
        <g id="el83b1mmhemc8">
          <rect
            x="116.29"
            y="338.73"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 340.245px',
              transform: 'rotate(-46.7deg)',
            }}
            className="animable"
            id="el2iob8809w6c"
          />
        </g>
        <rect
          x="121.18"
          y="351.01"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 352.525px',
          }}
          id="elo4zxvffld7"
          className="animable"
        />
        <rect
          x="121.18"
          y="327.4"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 328.915px',
          }}
          id="el8cqqxq5o8kl"
          className="animable"
        />
        <g id="el31uvaapukc">
          <rect
            x="130.34"
            y="324.68"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 340.25px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="el0ol44yfutuz"
          />
        </g>
        <g id="elc1dsdufl13b">
          <rect
            x="116.29"
            y="362.34"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '131.86px 363.855px',
              transform: 'rotate(-46.7deg)',
            }}
            className="animable"
            id="elyq5mkepjnaq"
          />
        </g>
        <rect
          x="121.18"
          y="374.62"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 376.135px',
          }}
          id="eli66o6wz3fv"
          className="animable"
        />
        <rect
          x="121.18"
          y="351.01"
          width="21.88"
          height="3.03"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '132.12px 352.525px',
          }}
          id="elbipiamulop"
          className="animable"
        />
        <g id="el8q9krahei83">
          <rect
            x="130.34"
            y="348.28"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.855px 363.85px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="elpfxe20a6q1f"
          />
        </g>
        <g id="eloxxhi55n1cs">
          <rect
            x="124.79"
            y="365.39"
            width="13.75"
            height="31.56"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '131.665px 381.17px',
              transform: 'rotate(90deg)',
            }}
            className="animable"
            id="elsirpz1xzypb"
          />
        </g>
        <g id="elyhl4cvtcyar">
          <rect
            x="-9.67"
            y="241.71"
            width={259}
            height="7.91"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '119.83px 245.665px',
              transform: 'rotate(90deg)',
            }}
            className="animable"
            id="elpqxma6cyx7i"
          />
        </g>
        <g id="elpm8tv090r9s">
          <rect
            x="13.98"
            y="241.71"
            width={259}
            height="7.91"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '143.48px 245.665px',
              transform: 'rotate(90deg)',
            }}
            className="animable"
            id="el22l8bix7ds3h"
          />
        </g>
        <polygon
          points="355.33 114.31 331.69 91.01 132.15 53.83 71.51 91.19 69.92 88.6 131.56 50.63 333.15 88.2 357.46 112.14 355.33 114.31"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '213.69px 82.47px',
          }}
          id="elmsrobeuzu4"
          className="animable"
        />
        <polygon
          points="156.21 94.28 131.91 60.5 108.65 94.23 103.65 90.78 131.8 49.96 161.14 90.73 156.21 94.28"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '132.395px 72.12px',
          }}
          id="el4s49fki15iu"
          className="animable"
        />
        <g id="el95jafzi8qzc">
          <rect
            x="318.2"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '319.715px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elqbbm4oxmq1"
          />
        </g>
        <rect
          x="330.48"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '331.995px 105.22px',
          }}
          id="els8cicskf4o"
          className="animable"
        />
        <rect
          x="306.87"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '308.385px 105.22px',
          }}
          id="eld8aada8e8nm"
          className="animable"
        />
        <g id="eluvwvz52s7vb">
          <rect
            x="304.15"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '319.72px 105.475px',
              transform: 'rotate(-43.31deg)',
            }}
            className="animable"
            id="eltxbzn73kcy"
          />
        </g>
        <g id="el3d30pf3cc3j">
          <rect
            x="294.59"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '296.105px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elei7bbqlt5oe"
          />
        </g>
        <rect
          x="306.87"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '308.385px 105.22px',
          }}
          id="elav41lmo8fdj"
          className="animable"
        />
        <rect
          x="283.26"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '284.775px 105.22px',
          }}
          id="eldxbz222ydul"
          className="animable"
        />
        <g id="elyjhbsxojsfh">
          <rect
            x="280.54"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '296.11px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="elkgk5vlnohkn"
          />
        </g>
        <g id="el4tpg94vv79y">
          <rect
            x="270.98"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '272.495px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="el8v62am4nazg"
          />
        </g>
        <rect
          x="283.26"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '284.775px 105.22px',
          }}
          id="eliu6ks6u8rdn"
          className="animable"
        />
        <rect
          x="259.65"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '261.165px 105.22px',
          }}
          id="elzflx9kp7op"
          className="animable"
        />
        <g id="el4tlufrs1kco">
          <rect
            x="256.93"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '272.5px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="elk1q1sfavqb"
          />
        </g>
        <g id="elwffox8wuywa">
          <rect
            x="247.37"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '248.885px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elhassq0k3eh6"
          />
        </g>
        <rect
          x="259.65"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '261.165px 105.22px',
          }}
          id="elh5twg6pq9pn"
          className="animable"
        />
        <rect
          x="236.04"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '237.555px 105.22px',
          }}
          id="eldpubglaldnp"
          className="animable"
        />
        <g id="elhaw66d3g2w">
          <rect
            x="233.32"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '248.89px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="elkkhsvsb4wsn"
          />
        </g>
        <g id="elogylv7vwr3n">
          <rect
            x="223.77"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '225.285px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elfw79it9aed8"
          />
        </g>
        <rect
          x="236.04"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '237.555px 105.22px',
          }}
          id="eldc085bxffp9"
          className="animable"
        />
        <rect
          x="212.44"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '213.955px 105.22px',
          }}
          id="elifki1r9515"
          className="animable"
        />
        <g id="el2q5uncbh0kr">
          <rect
            x="209.71"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '225.28px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="eloqtlllon9nr"
          />
        </g>
        <g id="el4jj5ks0oa2g">
          <rect
            x="200.16"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '201.675px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elu2uuunsbqn9"
          />
        </g>
        <rect
          x="212.44"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '213.955px 105.22px',
          }}
          id="el4gysq2zye0q"
          className="animable"
        />
        <rect
          x="188.83"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '190.345px 105.22px',
          }}
          id="elk7a710hgstp"
          className="animable"
        />
        <g id="elretxqsjuodi">
          <rect
            x="186.1"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '201.67px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="eltcfo5kq108"
          />
        </g>
        <g id="el60ap8ytol3w">
          <rect
            x="176.55"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '178.065px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elyh5ct60vnu"
          />
        </g>
        <rect
          x="188.83"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '190.345px 105.22px',
          }}
          id="elmfnzvs4k6dj"
          className="animable"
        />
        <rect
          x="165.22"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '166.735px 105.22px',
          }}
          id="elmiwpjlty8qd"
          className="animable"
        />
        <g id="el9qu8xg66on">
          <rect
            x="162.5"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '178.07px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="elktvm21qsuv"
          />
        </g>
        <g id="elp0ttmrsa7aq">
          <rect
            x="152.94"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '154.455px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="el95gcliisgag"
          />
        </g>
        <rect
          x="165.22"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '166.735px 105.22px',
          }}
          id="elpk3e6fm72k"
          className="animable"
        />
        <rect
          x="141.61"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '143.125px 105.22px',
          }}
          id="elso0da3zl2ds"
          className="animable"
        />
        <g id="el4sknbsy675x">
          <rect
            x="138.89"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '154.46px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="elvm7e6t7sdbm"
          />
        </g>
        <g id="el9y4qttqpqxa">
          <rect
            x="129.33"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '130.845px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elim3b4dsmzz"
          />
        </g>
        <rect
          x="141.61"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '143.125px 105.22px',
          }}
          id="elxppwc6x9czl"
          className="animable"
        />
        <rect
          x={118}
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '119.515px 105.22px',
          }}
          id="eldc5awxvj4p"
          className="animable"
        />
        <g id="el9tvl2afvur">
          <rect
            x="115.28"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '130.85px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="el3mj1w9ewnva"
          />
        </g>
        <g id="el91chn1vibva">
          <rect
            x="105.72"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '107.235px 105.48px',
              transform: 'rotate(-46.69deg)',
            }}
            className="animable"
            id="elev85y3apl2p"
          />
        </g>
        <rect
          x={118}
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '119.515px 105.22px',
          }}
          id="el2kcclk77vah"
          className="animable"
        />
        <rect
          x="94.39"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '95.905px 105.22px',
          }}
          id="eluvj126np1x"
          className="animable"
        />
        <g id="elg9s5edo3y5p">
          <rect
            x="91.67"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '107.24px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="elsm7ffepawy"
          />
        </g>
        <g id="ele69x1leofj">
          <rect
            x="82.11"
            y="89.91"
            width="3.03"
            height="31.14"
            style={{
              fill: 'rgb(38, 50, 56)',
              transformOrigin: '83.625px 105.48px',
              transform: 'rotate(-46.6135deg)',
            }}
            className="animable"
            id="eloalifnt2x1o"
          />
        </g>
        <rect
          x="94.39"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '95.905px 105.22px',
          }}
          id="ellhjlf9fkua"
          className="animable"
        />
        <rect
          x="70.78"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '72.295px 105.22px',
          }}
          id="el5o40iruam4x"
          className="animable"
        />
        <g id="elv74me6o2fon">
          <rect
            x="68.06"
            y="103.96"
            width="31.14"
            height="3.03"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '83.63px 105.475px',
              transform: 'rotate(-43.32deg)',
            }}
            className="animable"
            id="el3e7k4y7cacb"
          />
        </g>
        <rect
          x="56.16"
          y="88.55"
          width="13.73"
          height="31.56"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '63.025px 104.33px',
          }}
          id="elbbg4ia5rnrd"
          className="animable"
        />
        <rect
          x="69.89"
          y="88.55"
          width="13.75"
          height="31.56"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '76.765px 104.33px',
          }}
          id="elgt96431szuv"
          className="animable"
        />
        <rect
          x="75.43"
          y="120.11"
          width="26.36"
          height="15.74"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '88.61px 127.98px',
          }}
          id="elxv55mh5v7d"
          className="animable"
        />
        <rect
          x="69.89"
          y="112.2"
          width="287.57"
          height="7.91"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '213.675px 116.155px',
          }}
          id="elaypjbds3rmb"
          className="animable"
        />
        <polygon
          points="341.05 96.46 69.89 96.46 69.89 88.55 333.32 88.55 341.05 96.46"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '205.47px 92.505px',
          }}
          id="el54iemi818r2"
          className="animable"
        />
        <rect
          x="335.65"
          y="94.28"
          width="3.03"
          height="21.88"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '337.165px 105.22px',
          }}
          id="elnb0fkf5jbfm"
          className="animable"
        />
        <polygon
          points="227.92 206.13 246.92 203.09 265.92 200.15 303.96 194.42 304.06 194.41 304.16 194.43 367.46 210.85 399.1 219.14 430.7 227.54 398.97 219.63 367.27 211.6 303.91 195.39 304.11 195.4 266.03 200.89 246.99 203.56 227.92 206.13"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '329.31px 210.975px',
          }}
          id="elbne4lp74yag"
          className="animable"
        />
        <rect
          x="289.38"
          y="123.34"
          width="3.03"
          height="44.8"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '290.895px 145.74px',
          }}
          id="el1vrt9dk3bfe"
          className="animable"
        />
        <rect
          x="315.05"
          y="124.34"
          width="3.03"
          height="44.8"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '316.565px 146.74px',
          }}
          id="elxydx4tgmkha"
          className="animable"
        />
        <rect
          x="283.94"
          y="120.11"
          width="40.01"
          height="6.45"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '303.945px 123.335px',
          }}
          id="elb8brcto7nj"
          className="animable"
        />
        <path
          d="M305.46,175.67l-.34,6.73-1-1.16a10.78,10.78,0,0,1,3.06,1.32,7.21,7.21,0,0,1,2.4,2.36,6,6,0,0,1-.16,6.4,6.24,6.24,0,0,1-5.41,2.8,6.58,6.58,0,0,1-5-3,6.28,6.28,0,0,0,5,2.4,5.39,5.39,0,0,0,4.41-2.82,4.55,4.55,0,0,0-.3-4.81,7,7,0,0,0-4.41-2.33l-.92-.16-.05-1-.33-6.73Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '304.718px 184.897px',
          }}
          id="el4e1pqyn4hqd"
          className="animable"
        />
        <polygon
          points="323.95 161.87 323.95 172.85 303.94 177.6 283.94 172.85 283.94 161.87 323.95 161.87"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '303.945px 169.735px',
          }}
          id="elmf53s8dm3so"
          className="animable"
        />
        <path
          d="M293.12,168.31a2.33,2.33,0,1,1-2.33-2.32A2.32,2.32,0,0,1,293.12,168.31Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '290.79px 168.32px',
          }}
          id="ele7ylbrv6z0h"
          className="animable"
        />
        <path
          d="M319.41,168.31a2.32,2.32,0,1,1-2.32-2.32A2.32,2.32,0,0,1,319.41,168.31Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '317.09px 168.31px',
          }}
          id="eltoutt5se34b"
          className="animable"
        />
        <g id="eldhrjftaq1op">
          <rect
            x="223.58"
            y="216.44"
            width="205.22"
            height="46.55"
            style={{
              fill: '#F1923D',
              transformOrigin: '326.19px 239.715px',
              transform: 'rotate(-173.86deg)',
            }}
            className="animable"
            id="elzl06c7aqip"
          />
        </g>
        <polygon
          points="333.24 253.79 426.8 263.86 382.72 233.66 333.24 253.79"
          style={{ fill: '#F1923D', transformOrigin: '380.02px 248.76px' }}
          id="el1ufu0nto7nv"
          className="animable"
        />
        <g id="el208m9lrpjcq">
          <polygon
            points="333.24 253.79 426.8 263.86 382.72 233.66 333.24 253.79"
            style={{ opacity: '0.2', transformOrigin: '380.02px 248.76px' }}
            className="animable"
            id="elsju6uun7p2f"
          />
        </g>
        <polygon
          points="287.17 248.86 370.14 257.79 330.42 236.94 287.17 248.86"
          style={{ fill: '#F1923D', transformOrigin: '328.655px 247.365px' }}
          id="eljxhe9zbhzza"
          className="animable"
        />
        <g id="eleu0myztba9r">
          <polygon
            points="287.17 248.86 370.14 257.79 330.42 236.94 287.17 248.86"
            style={{ opacity: '0.1', transformOrigin: '328.655px 247.365px' }}
            className="animable"
            id="eljc7p83yc43m"
          />
        </g>
        <g id="elm1gnir4xhe">
          <rect
            x="221.63"
            y="252.86"
            width="205.22"
            height="10.03"
            style={{
              fill: '#F1923D',
              transformOrigin: '324.24px 257.875px',
              transform: 'rotate(6.14deg)',
            }}
            className="animable"
            id="eleeketzite5b"
          />
        </g>
        <g id="el3ejn444t53o">
          <rect
            x="221.63"
            y="252.86"
            width="205.22"
            height="10.03"
            style={{
              opacity: '0.1',
              transformOrigin: '324.24px 257.875px',
              transform: 'rotate(6.14deg)',
            }}
            className="animable"
            id="el4nzdpfz02nd"
          />
        </g>
        <g id="elmqlz0jjszkh">
          <path
            d="M251.82,223.65a6.33,6.33,0,1,1-5.61-7A6.32,6.32,0,0,1,251.82,223.65Z"
            style={{
              fill: 'rgb(255, 255, 255)',
              opacity: '0.6',
              transformOrigin: '245.53px 222.943px',
            }}
            className="animable"
            id="elrjhsecqw0rg"
          />
        </g>
        <path
          d="M137,52.23a5.12,5.12,0,1,1-5.12-5.12A5.12,5.12,0,0,1,137,52.23Z"
          style={{
            fill: 'rgb(166, 166, 166)',
            transformOrigin: '131.88px 52.23px',
          }}
          id="elaxw1cmtsjvk"
          className="animable"
        />
        <path
          d="M134.25,52.23a2.4,2.4,0,1,1-2.39-2.39A2.39,2.39,0,0,1,134.25,52.23Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '131.85px 52.24px',
          }}
          id="elx88grrb1ku"
          className="animable"
        />
        <rect
          x="109.57"
          y="388.05"
          width="44.18"
          height="10.44"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '131.66px 393.27px',
          }}
          id="el67fib0bbo5"
          className="animable"
        />
        <rect
          x="99.99"
          y="398.49"
          width="63.34"
          height="20.48"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '131.66px 408.73px',
          }}
          id="eltyu2kwyhy5"
          className="animable"
        />
        <path
          d="M157.35,138.37H110.07V86H141a10.78,10.78,0,0,1,10.69,9.36Z"
          style={{ fill: '#F1923D', transformOrigin: '133.71px 112.185px' }}
          id="elogy3kni47j9"
          className="animable"
        />
        <path
          d="M144.48,122.47H122.32a2.07,2.07,0,0,1-.71-.11,2.5,2.5,0,0,1-1.78-2.39V92h15.76a7.49,7.49,0,0,1,1.58.15,8.54,8.54,0,0,1,3.31,1.32,8.38,8.38,0,0,1,1.84,1.67,8.76,8.76,0,0,1,2,4.49l2.66,20A2.52,2.52,0,0,1,144.48,122.47Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '133.415px 107.235px',
          }}
          id="elvn038fdct8"
          className="animable"
        />
        <g id="el0oohc04atu0q">
          <g
            style={{ opacity: '0.4', transformOrigin: '131.075px 107.235px' }}
            className="animable"
            id="elmaus1wt3uga"
          >
            <path
              d="M142.1,107.88a2.44,2.44,0,0,1-.73,1.75l-12.83,12.84h-6.22a2.07,2.07,0,0,1-.71-.11l16.25-16.24a2.49,2.49,0,0,1,4.24,1.76Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '131.855px 113.936px',
              }}
              id="elrs3aadac2p9"
              className="animable"
            />
            <path
              d="M142.32,95.15l-22.49,22.48v-3.5l20.65-20.65A8.38,8.38,0,0,1,142.32,95.15Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '131.075px 105.555px',
              }}
              id="elhqum6b8luh6"
              className="animable"
            />
            <path
              d="M137.17,92.16,124.4,104.93a1.87,1.87,0,0,1-3.19-1.32,1.84,1.84,0,0,1,.55-1.3L132.06,92h3.53A7.49,7.49,0,0,1,137.17,92.16Z"
              style={{
                fill: 'rgb(255, 255, 255)',
                transformOrigin: '129.19px 98.7377px',
              }}
              id="el19gg6oqeqaf"
              className="animable"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Fence--inject-6"
        className="animable"
        style={{ transformOrigin: '144.645px 374.3px' }}
      >
        <polygon
          points="106.73 356.33 105.78 363.13 104.75 370.44 95.35 437.05 79.99 437.05 89.39 370.44 90.42 363.13 91.37 356.33 106.73 356.33"
          style={{ fill: '#F1923D', transformOrigin: '93.36px 396.69px' }}
          id="elywvdikzgfcn"
          className="animable"
        />
        <g id="elz0iqcevd6s9">
          <path
            d="M104.51,356.33c-.81,6.75-1.68,13.49-2.56,20.22l-2.71,20.2L96.4,416.91,94.94,427l-1.53,10.06,1.25-10.11L96,416.83l2.71-20.19,2.84-20.17C102.49,369.75,103.47,363,104.51,356.33Z"
            style={{ opacity: '0.1', transformOrigin: '98.96px 396.695px' }}
            className="animable"
            id="elzg2v09ylpw"
          />
        </g>
        <g id="elmc26ubn7vh">
          <polygon
            points="105.78 363.13 104.75 370.44 89.39 370.44 90.42 363.13 105.78 363.13"
            style={{ opacity: '0.1', transformOrigin: '97.585px 366.785px' }}
            className="animable"
            id="eltjut07wr4wb"
          />
        </g>
        <polygon
          points="182.56 356.33 183.51 363.13 184.54 370.44 193.93 437.05 209.29 437.05 199.9 370.44 198.87 363.13 197.92 356.33 182.56 356.33"
          style={{ fill: '#F1923D', transformOrigin: '195.925px 396.69px' }}
          id="elfq9vjoztc28"
          className="animable"
        />
        <g id="elyq64td937y9">
          <path
            d="M184.83,356.33c.81,6.75,1.68,13.49,2.56,20.22l2.71,20.2,2.84,20.16L194.4,427l1.53,10.06-1.25-10.11-1.31-10.11-2.71-20.19-2.84-20.17C186.85,369.75,185.87,363,184.83,356.33Z"
            style={{ opacity: '0.1', transformOrigin: '190.38px 396.695px' }}
            className="animable"
            id="elzpw0s7v6st"
          />
        </g>
        <g id="elchq54nacgc9">
          <polygon
            points="183.51 363.13 198.87 363.13 199.9 370.44 184.54 370.44 183.51 363.13"
            style={{ opacity: '0.1', transformOrigin: '191.705px 366.785px' }}
            className="animable"
            id="elygi3nbmiu39"
          />
        </g>
        <polygon
          points="106.17 342.36 93.99 342.36 95.51 331.01 104.66 331.01 106.17 342.36"
          style={{ fill: '#F1923D', transformOrigin: '100.08px 336.685px' }}
          id="els8fetlkciia"
          className="animable"
        />
        <g id="el1np6l3s4wby">
          <polygon
            points="106.17 342.36 93.99 342.36 95.51 331.01 104.66 331.01 106.17 342.36"
            style={{ opacity: '0.1', transformOrigin: '100.08px 336.685px' }}
            className="animable"
            id="elmj1rv8byyf"
          />
        </g>
        <path
          d="M111.43,322.89a11.35,11.35,0,1,1-11.35-11.35A11.35,11.35,0,0,1,111.43,322.89Z"
          style={{ fill: '#F1923D', transformOrigin: '100.08px 322.89px' }}
          id="el8se6ohhsbke"
          className="animable"
        />
        <path
          d="M108.71,322.89a8.63,8.63,0,1,1-8.63-8.62A8.63,8.63,0,0,1,108.71,322.89Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '100.08px 322.9px',
          }}
          id="elrtd1wora2x9"
          className="animable"
        />
        <path
          d="M107.29,322.39a7.47,7.47,0,0,0-2.81-5.29,7.66,7.66,0,0,0-5.81-1.52,7.27,7.27,0,0,0-5,3.24,7.59,7.59,0,0,0-.91,6.22,7.11,7.11,0,0,0,3.37,4.27,7.47,7.47,0,0,0,5.28.81,7.75,7.75,0,0,0,4.43-2.71,8.23,8.23,0,0,0,1.82-4.81c0-.08,0-.15-.07-.15s-.08.06-.08.15a8.15,8.15,0,0,1-.4,2.08,8,8,0,0,1-2.37,3.42,9,9,0,0,1-1.79,1.16,7.2,7.2,0,0,1-5.89.14,7.07,7.07,0,0,1-4-4.38,7.3,7.3,0,0,1,.76-6,7,7,0,0,1,4.86-3.2,7.49,7.49,0,0,1,7.14,2.85,7.76,7.76,0,0,1,1,1.79,9.15,9.15,0,0,1,.45,2s0,0,0,0A.06.06,0,0,0,107.29,322.39Z"
          style={{ fill: '#F1923D', transformOrigin: '100.06px 322.893px' }}
          id="elp7lqq6sv2wp"
          className="animable"
        />
        <path
          d="M106.28,322.4a6.47,6.47,0,0,0-2.44-4.52,6.63,6.63,0,0,0-5-1.31,6.24,6.24,0,0,0-4.31,2.75,6.58,6.58,0,0,0-.84,5.39,6.17,6.17,0,0,0,2.92,3.73,6.6,6.6,0,0,0,4.61.69,6.72,6.72,0,0,0,3.83-2.38,7.2,7.2,0,0,0,1.58-4.17c0-.07,0-.13-.07-.13s-.08.05-.08.13a6.89,6.89,0,0,1-3.94,5.75,6.19,6.19,0,0,1-5.09.15A6.11,6.11,0,0,1,94,324.67a6.31,6.31,0,0,1,.69-5.2,6,6,0,0,1,4.19-2.7,6.49,6.49,0,0,1,6.09,2.42,6.91,6.91,0,0,1,.84,1.53,8.05,8.05,0,0,1,.41,1.71,0,0,0,0,0,0,0A0,0,0,0,0,106.28,322.4Z"
          style={{ fill: '#F1923D', transformOrigin: '100.038px 322.89px' }}
          id="el73j6kn8nfg5"
          className="animable"
        />
        <path
          d="M105.27,322.41a5.4,5.4,0,0,0-2.07-3.74,5.57,5.57,0,0,0-4.11-1.11,5.23,5.23,0,0,0-3.64,2.25,5.58,5.58,0,0,0-.77,4.56,5.14,5.14,0,0,0,2.48,3.19,5.48,5.48,0,0,0,3.93.57,5.71,5.71,0,0,0,3.24-2,6.21,6.21,0,0,0,1.33-3.53c0-.06,0-.11-.07-.11s-.08,0-.08.11a5.9,5.9,0,0,1-3.32,4.84,5.2,5.2,0,0,1-4.3.15A5.07,5.07,0,0,1,95,324.33a5.27,5.27,0,0,1,.63-4.37,5,5,0,0,1,3.51-2.2,6,6,0,0,1,2.78.33,5.19,5.19,0,0,1,2.26,1.66,5.59,5.59,0,0,1,.71,1.26,6.74,6.74,0,0,1,.37,1.42s0,0,0,0S105.28,322.43,105.27,322.41Z"
          style={{ fill: '#F1923D', transformOrigin: '100.076px 322.892px' }}
          id="ele8vxh9xdqbt"
          className="animable"
        />
        <path
          d="M104.26,322.42a4.4,4.4,0,0,0-1.7-3,4.64,4.64,0,0,0-3.26-.91,4.24,4.24,0,0,0-3,1.77,4.57,4.57,0,0,0-.7,3.73,4.19,4.19,0,0,0,2,2.65,4.47,4.47,0,0,0,3.25.44,4.68,4.68,0,0,0,2.65-1.71,5.17,5.17,0,0,0,1.08-2.88c0-.05,0-.09-.07-.09a.09.09,0,0,0-.08.09,4.86,4.86,0,0,1-2.7,3.92,4.16,4.16,0,0,1-3.5.17A4.09,4.09,0,0,1,95.93,324a4.26,4.26,0,0,1,.56-3.54,3.94,3.94,0,0,1,2.84-1.69,5.15,5.15,0,0,1,2.19.28,4.13,4.13,0,0,1,1.8,1.29,4.64,4.64,0,0,1,.59,1,6.83,6.83,0,0,1,.32,1.14h0S104.27,322.43,104.26,322.42Z"
          style={{ fill: '#F1923D', transformOrigin: '100.023px 322.858px' }}
          id="elfjk1kccjf"
          className="animable"
        />
        <polygon
          points="195.03 342.36 182.85 342.36 184.37 331.01 193.52 331.01 195.03 342.36"
          style={{ fill: '#F1923D', transformOrigin: '188.94px 336.685px' }}
          id="elkp7wmm5a10k"
          className="animable"
        />
        <g id="el54kkyp6phaq">
          <polygon
            points="195.03 342.36 182.85 342.36 184.37 331.01 193.52 331.01 195.03 342.36"
            style={{ opacity: '0.1', transformOrigin: '188.94px 336.685px' }}
            className="animable"
            id="el36xwv7crbr5"
          />
        </g>
        <path
          d="M200.29,322.89a11.35,11.35,0,1,1-11.35-11.35A11.35,11.35,0,0,1,200.29,322.89Z"
          style={{ fill: '#F1923D', transformOrigin: '188.94px 322.89px' }}
          id="ellgqoz4dbtr8"
          className="animable"
        />
        <path
          d="M197.57,322.89a8.63,8.63,0,1,1-8.63-8.62A8.63,8.63,0,0,1,197.57,322.89Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '188.94px 322.9px',
          }}
          id="elbw5w1gbj8ym"
          className="animable"
        />
        <path
          d="M196.15,322.39a7.47,7.47,0,0,0-2.81-5.29,7.66,7.66,0,0,0-5.81-1.52,7.27,7.27,0,0,0-5,3.24,7.59,7.59,0,0,0-.91,6.22,7.11,7.11,0,0,0,3.37,4.27,7.47,7.47,0,0,0,5.28.81,7.75,7.75,0,0,0,4.43-2.71,8.16,8.16,0,0,0,1.82-4.81c0-.08,0-.15-.07-.15s-.08.06-.08.15a7.74,7.74,0,0,1-.4,2.08,8,8,0,0,1-2.37,3.42,9,9,0,0,1-1.79,1.16,7.2,7.2,0,0,1-5.89.14,7.07,7.07,0,0,1-4-4.38,7.3,7.3,0,0,1,.76-6,7,7,0,0,1,4.86-3.2,7.49,7.49,0,0,1,7.14,2.85,7.76,7.76,0,0,1,1,1.79,9.15,9.15,0,0,1,.45,2s0,0,0,0A.11.11,0,0,0,196.15,322.39Z"
          style={{ fill: '#F1923D', transformOrigin: '188.92px 322.893px' }}
          id="elkk6desxo58c"
          className="animable"
        />
        <path
          d="M195.14,322.4a6.47,6.47,0,0,0-2.44-4.52,6.63,6.63,0,0,0-5-1.31,6.24,6.24,0,0,0-4.31,2.75,6.58,6.58,0,0,0-.84,5.39,6.17,6.17,0,0,0,2.92,3.73,6.6,6.6,0,0,0,4.61.69,6.72,6.72,0,0,0,3.83-2.38,7.2,7.2,0,0,0,1.58-4.17c0-.07,0-.13-.07-.13s-.08.05-.08.13a6.89,6.89,0,0,1-3.94,5.75,6.19,6.19,0,0,1-5.09.15,6.11,6.11,0,0,1-3.46-3.81,6.31,6.31,0,0,1,.69-5.2,6,6,0,0,1,4.19-2.7,6.49,6.49,0,0,1,6.09,2.42,6.91,6.91,0,0,1,.84,1.53,8.05,8.05,0,0,1,.41,1.71s0,0,0,0S195.15,322.42,195.14,322.4Z"
          style={{ fill: '#F1923D', transformOrigin: '188.898px 322.89px' }}
          id="el5glp5l3eqcy"
          className="animable"
        />
        <path
          d="M194.13,322.41a5.4,5.4,0,0,0-2.07-3.74,5.57,5.57,0,0,0-4.11-1.11,5.23,5.23,0,0,0-3.64,2.25,5.58,5.58,0,0,0-.77,4.56,5.14,5.14,0,0,0,2.48,3.19,5.48,5.48,0,0,0,3.93.57,5.71,5.71,0,0,0,3.24-2,6.14,6.14,0,0,0,1.33-3.53c0-.06,0-.11-.07-.11s-.08,0-.08.11a5.9,5.9,0,0,1-3.32,4.84,5.2,5.2,0,0,1-4.3.15,5.07,5.07,0,0,1-2.91-3.22,5.27,5.27,0,0,1,.63-4.37,5,5,0,0,1,3.51-2.2,6,6,0,0,1,2.78.33,5.06,5.06,0,0,1,3,2.92,6.74,6.74,0,0,1,.37,1.42s0,0,0,0S194.14,322.43,194.13,322.41Z"
          style={{ fill: '#F1923D', transformOrigin: '188.936px 322.892px' }}
          id="elh410fcbm23"
          className="animable"
        />
        <path
          d="M193.12,322.42a4.4,4.4,0,0,0-1.7-3,4.63,4.63,0,0,0-3.26-.91,4.24,4.24,0,0,0-3,1.77,4.6,4.6,0,0,0-.7,3.73,4.19,4.19,0,0,0,2,2.65,4.47,4.47,0,0,0,3.25.44,4.68,4.68,0,0,0,2.65-1.71,5.17,5.17,0,0,0,1.08-2.88c0-.05,0-.09-.07-.09a.09.09,0,0,0-.08.09,4.86,4.86,0,0,1-2.7,3.92,4.16,4.16,0,0,1-3.5.17,4.09,4.09,0,0,1-2.37-2.65,4.26,4.26,0,0,1,.56-3.54,3.94,3.94,0,0,1,2.84-1.69,5.15,5.15,0,0,1,2.19.28,4.13,4.13,0,0,1,1.8,1.29,4.64,4.64,0,0,1,.59,1,6.83,6.83,0,0,1,.32,1.14h0S193.13,322.43,193.12,322.42Z"
          style={{ fill: '#F1923D', transformOrigin: '188.884px 322.858px' }}
          id="el6ak7b7snety"
          className="animable"
        />
        <rect
          x="68.12"
          y="336.57"
          width="153.04"
          height="29.27"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '144.64px 351.205px',
          }}
          id="elgqk80od9hbm"
          className="animable"
        />
        <path
          d="M221.42,366.09H67.87V336.32H221.42Zm-153-.5H220.92V336.82H68.37Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '144.645px 351.205px',
          }}
          id="elabcz8qmhp3b"
          className="animable"
        />
        <polygon
          points="99.58 336.57 88.47 365.85 68.71 365.85 79.81 336.57 99.58 336.57"
          style={{ fill: '#F1923D', transformOrigin: '84.145px 351.21px' }}
          id="elwd3vn1p3vi"
          className="animable"
        />
        <polygon
          points="140.09 336.57 128.99 365.85 109.21 365.85 120.32 336.57 140.09 336.57"
          style={{ fill: '#F1923D', transformOrigin: '124.65px 351.21px' }}
          id="el0ix06pbs6sm"
          className="animable"
        />
        <polygon
          points="180.6 336.57 169.49 365.85 149.73 365.85 160.84 336.57 180.6 336.57"
          style={{ fill: '#F1923D', transformOrigin: '165.165px 351.21px' }}
          id="elteq77owfv5g"
          className="animable"
        />
        <polygon
          points="221.12 336.57 210.01 365.85 190.24 365.85 201.34 336.57 221.12 336.57"
          style={{ fill: '#F1923D', transformOrigin: '205.68px 351.21px' }}
          id="elfsm2oo1resh"
          className="animable"
        />
        <rect
          x="71.22"
          y="401.62"
          width="146.85"
          height="18.53"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '144.645px 410.885px',
          }}
          id="elwt6etg0yvzs"
          className="animable"
        />
        <path
          d="M218.32,420.4H71v-19H218.32Zm-146.85-.5H217.82v-18H71.47Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '144.66px 410.9px',
          }}
          id="elzc3wycqu43i"
          className="animable"
        />
        <polygon
          points="101.4 401.62 90.74 420.15 71.78 420.15 82.44 401.62 101.4 401.62"
          style={{ fill: '#F1923D', transformOrigin: '86.59px 410.885px' }}
          id="el75nsnmcy9g6"
          className="animable"
        />
        <polygon
          points="140.28 401.62 129.62 420.15 110.65 420.15 121.31 401.62 140.28 401.62"
          style={{ fill: '#F1923D', transformOrigin: '125.465px 410.885px' }}
          id="elp9y2sg2mjaq"
          className="animable"
        />
        <polygon
          points="179.14 401.62 168.49 420.15 149.52 420.15 160.18 401.62 179.14 401.62"
          style={{ fill: '#F1923D', transformOrigin: '164.33px 410.885px' }}
          id="elnhn04hadvlo"
          className="animable"
        />
        <polygon
          points="218.02 401.62 207.36 420.15 188.39 420.15 199.05 401.62 218.02 401.62"
          style={{ fill: '#F1923D', transformOrigin: '203.205px 410.885px' }}
          id="elgnltjduj04q"
          className="animable"
        />
        <g id="elxbb6wxqd1pi">
          <circle
            cx="144.64"
            cy="351.21"
            r="23.82"
            style={{
              fill: 'rgb(255, 255, 255)',
              transformOrigin: '144.64px 351.21px',
              transform: 'rotate(-76.58deg)',
            }}
            className="animable"
            id="elf40c60wx0xa"
          />
        </g>
        <path
          d="M144.64,375.27a24.07,24.07,0,1,1,24.07-24.06A24.08,24.08,0,0,1,144.64,375.27Zm0-47.63a23.57,23.57,0,1,0,23.57,23.57A23.59,23.59,0,0,0,144.64,327.64Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '144.64px 351.2px',
          }}
          id="elnfy19koyhd"
          className="animable"
        />
        <g id="elimtk4b92gy">
          <circle
            cx="144.64"
            cy="351.21"
            r="20.19"
            style={{
              fill: '#F1923D',
              transformOrigin: '144.64px 351.21px',
              transform: 'rotate(-76.6deg)',
            }}
            className="animable"
            id="el6po2inqi4nx"
          />
        </g>
        <rect
          x="131.98"
          y="348.86"
          width="25.32"
          height="4.69"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '144.64px 351.205px',
          }}
          id="el2evnu13aasx"
          className="animable"
        />
      </g>
      <g
        id="freepik--Cones--inject-6"
        className="animable"
        style={{ transformOrigin: '437.162px 420.552px' }}
      >
        <path
          d="M477.88,439.5l5.06-34.82,1.47.66,2.34,1.06L496.91,411,506,415.1l6,2.71,9.11,4.12,5,2.25a4.81,4.81,0,0,1-1.29,8.93l-2.11.29h0l-3.32.45-9.89,1.34-6.52.89-9.9,1.35-4.14.57Z"
          style={{ fill: '#F1923D', transformOrigin: '503.252px 422.09px' }}
          id="eld8s2hbx3lde"
          className="animable"
        />
        <g id="el475qf2fnoda">
          <path
            d="M484.66,405.65a22.28,22.28,0,0,1-1.86,3.76c-3.09,4.82-5.32,14.68-3.64,26a14.45,14.45,0,0,0,.75,3.59l2.12-.14s9.87-21.66,42.14-15.64l-3-1.38-9.1-4.1-6-2.71-9.1-4.12Z"
            style={{
              opacity: '0.1',
              mixBlendMode: 'multiply',
              transformOrigin: '501.377px 422.325px',
            }}
            className="animable"
            id="el2h4vyisk0qd"
          />
        </g>
        <polygon
          points="509.49 435.19 512.01 417.81 521.12 421.93 519.38 433.85 509.49 435.19"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '515.305px 426.5px',
          }}
          id="el6e89mayw97w"
          className="animable"
        />
        <polygon
          points="493.07 437.43 496.91 410.99 506.02 415.11 502.97 436.08 493.07 437.43"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '499.545px 424.21px',
          }}
          id="elimk5puamqj"
          className="animable"
        />
        <path
          d="M389,438.64h-33.9l.42-1.5.69-2.38,3-10.32,2.66-9.25,1.75-6.09,2.66-9.25,1.46-5.07a4.62,4.62,0,0,1,8.68,0l.57,2h0l.89,3.09,2.66,9.25,1.75,6.09,2.66,9.25,1.11,3.87Z"
          style={{ fill: '#F1923D', transformOrigin: '372.05px 415.192px' }}
          id="el5alkpprfphk"
          className="animable"
        />
        <polygon
          points="390.25 447.43 345.7 447.43 352.8 429.84 352.8 429.84 397.35 429.84 397.35 429.84 390.25 447.43 390.25 447.43"
          style={{ fill: '#F1923D', transformOrigin: '371.525px 438.635px' }}
          id="el01nz5t934hd7"
          className="animable"
        />
        <polygon
          points="380.55 409.1 363.63 409.1 366.29 399.86 377.89 399.86 380.55 409.1"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '372.09px 404.48px',
          }}
          id="el0p6rudw4npqj"
          className="animable"
        />
        <polygon
          points="384.96 424.44 359.22 424.44 361.88 415.19 382.3 415.19 384.96 424.44"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '372.09px 419.815px',
          }}
          id="elk1j4l9mpvsl"
          className="animable"
        />
        <path
          d="M354.66,436.28a35.42,35.42,0,0,0,8.6,2.61,46.42,46.42,0,0,0,8.89.68c1.49-.1,3-.11,4.46-.34.74-.1,1.49-.15,2.22-.28s1.47-.28,2.21-.4c.37-.07.74-.13,1.11-.21l1.09-.27,2.19-.53c1.45-.39,2.88-.88,4.36-1.26-1.37.65-2.75,1.3-4.16,1.86l-2.17.74-1.08.36c-.37.11-.74.19-1.11.29l-2.24.53c-.74.17-1.51.25-2.26.38a44,44,0,0,1-4.59.42,36.41,36.41,0,0,1-9.13-1A24.94,24.94,0,0,1,354.66,436.28Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '372.225px 438.573px',
          }}
          id="eljejihbzxxe"
          className="animable"
        />
        <g id="elcs6qji3tvb">
          <path
            d="M355.83,436.86a21.76,21.76,0,0,1,3.84,1.25c5,2.28,14.73,3,25.34-.14a14,14,0,0,0,3.31-1.21l-.43-2s-22-6.4-20.74-38l-.89,3.09-2.66,9.25-1.75,6.09-2.66,9.25Z"
            style={{
              opacity: '0.1',
              mixBlendMode: 'multiply',
              transformOrigin: '372.075px 418.419px',
            }}
            className="animable"
            id="eldbnxm590n4e"
          />
        </g>
        <polygon
          points="468.66 439.44 475.31 393.66 492.33 403.59 485.67 449.36 468.66 439.44 468.66 439.44"
          style={{ fill: '#F1923D', transformOrigin: '480.495px 421.51px' }}
          id="elud63iqorpqj"
          className="animable"
        />
        <path
          d="M485.61,422.53c-.95,8.46-4,15.06-6.84,14.74s-4.35-7.43-3.4-15.89,4-15.05,6.85-14.73S486.56,414.08,485.61,422.53Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '480.491px 421.96px',
          }}
          id="elzq8rtu7831"
          className="animable"
        />
      </g>
      <g
        id="freepik--Character--inject-6"
        className="animable"
        style={{ transformOrigin: '597.32px 295.665px' }}
      >
        <path
          d="M561.89,447.37c2,.62,58.49-.59,60.61-2,.81-.53,1.11-8.78,1.2-18.74,0-2.15,0-4.37,0-6.63,0-2.68,0-5.38-.05-8-.15-11.8-.51-22.19-.51-22.19l-36.78.68.43,22.19.15,8,.32,6.62s-21.43,10.15-23.59,11.94S559.9,446.76,561.89,447.37Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '592.343px 418.673px',
          }}
          id="el9839phr82mf"
          className="animable"
        />
        <path
          d="M561.89,447.37c2,.62,58.49-.59,60.61-2,.81-.53,1.11-8.78,1.2-18.74,0-2.15,0-4.37,0-6.63,0-2.68,0-5.38-.05-8l-36.86.68.15,8,.32,6.62s-21.43,10.15-23.59,11.94S559.9,446.76,561.89,447.37Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '592.343px 429.768px',
          }}
          id="el01hex2j9g1rv"
          className="animable"
        />
        <path
          d="M561.72,447.46c1.29.4,25.36,0,42.88-.65,4-.16,7.76-.34,10.75-.53,4.12-.26,6.88-.54,7.32-.83,1-.66,1.24-12.75,1.22-25.51l-37,.69.31,6.66s-21.54,10.19-23.72,12S559.73,446.83,561.72,447.46Z"
          style={{ fill: '#F1923D', transformOrigin: '592.349px 433.78px' }}
          id="elfn7zbabwezr"
          className="animable"
        />
        <path
          d="M562.48,445.17c9.52-.44,48-1.31,57.4-1,.08,0,.08,0,0,0-9.43.64-47.86,1.2-57.39,1.12C562.29,445.32,562.28,445.18,562.48,445.17Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '591.137px 444.701px',
          }}
          id="elebggv7zzf1"
          className="animable"
        />
        <path
          d="M585.85,425.2c3.74-.78,8.33-.3,11.25,2.31.12.1,0,.26-.15.22a53.24,53.24,0,0,0-11.07-2.06A.24.24,0,0,1,585.85,425.2Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '591.41px 426.298px',
          }}
          id="elttsjrmu6h2r"
          className="animable"
        />
        <path
          d="M583.18,426.89c3.74-.78,8.33-.3,11.25,2.31.12.1,0,.26-.15.22a52.59,52.59,0,0,0-11.07-2.06A.24.24,0,0,1,583.18,426.89Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '588.74px 427.988px',
          }}
          id="elb618gp7d99"
          className="animable"
        />
        <path
          d="M580.51,428.58c3.73-.78,8.33-.3,11.25,2.3.11.11,0,.26-.15.23a52.71,52.71,0,0,0-11.07-2.06A.24.24,0,0,1,580.51,428.58Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '586.068px 429.677px',
          }}
          id="ele1dt4lm9rkc"
          className="animable"
        />
        <path
          d="M577.84,430.27c3.73-.78,8.33-.31,11.25,2.3.11.1,0,.26-.15.23a52.71,52.71,0,0,0-11.07-2.06A.24.24,0,0,1,577.84,430.27Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '583.398px 431.366px',
          }}
          id="elrrxlta5vo6r"
          className="animable"
        />
        <path
          d="M580.54,422c2.69,2,6.16,2.78,9.28,3.86.32.11.57-.2.58-.48.06,0,.11-.06.08-.14a19.88,19.88,0,0,0-6-8.46c-1.59-1.28-4.36-2.14-5.78-.08S579,420.81,580.54,422Zm-1-3c-.75-4.1,3.71-2,4.85-1a20.14,20.14,0,0,1,2,2.1c1.33,1.53,2.42,3.22,3.59,4.87-1.5-.63-3-1.12-4.56-1.69C583.61,422.56,579.93,421.25,579.52,419Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '584.33px 420.673px',
          }}
          id="el4xr94x4al1q"
          className="animable"
        />
        <path
          d="M602.87,419.24c-1-2.32-3.83-2.07-5.66-1.16a20,20,0,0,0-7.63,7,.1.1,0,0,0,.06.15c-.06.28.12.64.46.6,3.28-.39,6.83-.4,9.89-1.78C601.78,423.23,603.78,421.43,602.87,419.24Zm-7.92,5c-1.61.23-3.22.38-4.82.67a59,59,0,0,1,4.54-4,22,22,0,0,1,2.44-1.63c1.32-.72,6.12-1.79,4.51,2.06C600.74,423.45,596.86,423.94,595,424.21Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '596.331px 421.629px',
          }}
          id="elnz6j72fz4uo"
          className="animable"
        />
        <g id="el40rpj3ehwxe">
          <path
            d="M590.63,447.26c4.76-.13,9.57-.28,14-.45,4-.16,7.76-.34,10.75-.53,4.11-.26,6.88-.54,7.32-.84.86-.57,1.17-9.91,1.21-20.79C615.5,430.25,599,441.52,590.63,447.26Z"
            style={{ opacity: '0.2', transformOrigin: '607.27px 435.955px' }}
            className="animable"
            id="el8jpfvgxmms"
          />
        </g>
        <path
          d="M583.64,426.86l42,0s-2.3-94.49-1.85-96.69c1.08-5.26,25-88.56,25-88.56l-33.15.57-11.32.19s1,13.88.58,15.35c-5.54,20.21-16.35,54.72-18.56,67.51C582.45,347.44,583.64,426.86,583.64,426.86Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '616.129px 334.235px',
          }}
          id="elatv3vdlctkh"
          className="animable"
        />
        <path
          d="M585,419.84c6.54-.17,29.74-.86,38.47-.2.06,0,.06.11,0,.12-3,.49-31.92.35-38.47.26A.09.09,0,1,1,585,419.84Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '604.212px 419.735px',
          }}
          id="el5vmmfqqdgcu"
          className="animable"
        />
        <path
          d="M620.47,328.12c1.25-5.11,10.6-36.93,13.46-47s5.54-20.14,8-30.29c.31-1.29.63-2.57,1-3.85,0-.11.19-.06.17.05-.94,5.11-2.19,10.17-3.39,15.23s-2.49,10.09-3.83,15.11q-4.07,15.14-8.87,30.06c-1.6,4.95-4.24,15.13-5.65,20.14s.89,87.3.75,88.64a.3.3,0,0,1-.6,0C621.31,406.53,619.23,333.17,620.47,328.12Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '631.59px 331.714px',
          }}
          id="elt1ycmtokbv"
          className="animable"
        />
        <path
          d="M615.56,242.21s8.11,88.42,8.2,88c1-5.08,23.49-83.42,24.9-88.32l.06-.23Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '632.14px 285.936px',
          }}
          id="elcst6iz1wxeq"
          className="animable"
        />
        <path
          d="M605.42,452.21c2,.62,58.49-.59,60.62-2,.8-.53,1.11-8.77,1.19-18.74,0-2.15,0-4.37,0-6.63,0-2.67,0-5.38-.06-8-.15-11.8-.5-22.19-.5-22.19l-36.79.68.43,22.19.16,8,.31,6.63s-21.42,10.14-23.58,11.93S603.44,451.6,605.42,452.21Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '635.875px 423.513px',
          }}
          id="el934qxzy8j54"
          className="animable"
        />
        <path
          d="M605.42,452.21c2,.62,58.49-.59,60.62-2,.8-.53,1.11-8.77,1.19-18.74,0-2.15,0-4.37,0-6.63,0-2.67,0-5.38-.06-8l-36.86.68.16,8,.31,6.63s-21.42,10.14-23.58,11.93S603.44,451.6,605.42,452.21Z"
          style={{
            fill: 'rgb(255, 255, 255)',
            transformOrigin: '635.875px 434.608px',
          }}
          id="elqp7wz03ucig"
          className="animable"
        />
        <path
          d="M605.25,452.3c1.3.4,25.37,0,42.88-.65,4.06-.16,7.76-.34,10.75-.52,4.12-.27,6.88-.55,7.33-.84,1-.66,1.23-12.75,1.22-25.51l-37,.69.32,6.66s-21.54,10.2-23.72,12S603.26,451.67,605.25,452.3Z"
          style={{ fill: '#F1923D', transformOrigin: '635.886px 438.62px' }}
          id="elifnvzt06s4"
          className="animable"
        />
        <path
          d="M606,450c9.52-.44,48-1.3,57.39-1,.08,0,.08.05,0,.06-9.42.64-47.86,1.19-57.39,1.11C605.82,450.17,605.82,450,606,450Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '634.658px 449.558px',
          }}
          id="elfx6fw75t24t"
          className="animable"
        />
        <path
          d="M629.39,430c3.73-.78,8.33-.3,11.25,2.31.11.1,0,.26-.16.23a52,52,0,0,0-11.07-2.06A.24.24,0,0,1,629.39,430Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '634.922px 431.102px',
          }}
          id="elkfndspk7dzk"
          className="animable"
        />
        <path
          d="M626.72,431.73c3.73-.78,8.33-.3,11.25,2.31.11.1,0,.26-.16.22a52.57,52.57,0,0,0-11.07-2.05A.24.24,0,0,1,626.72,431.73Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '632.252px 432.828px',
          }}
          id="el0znihutp1a6"
          className="animable"
        />
        <path
          d="M624.05,433.42c3.73-.78,8.33-.3,11.25,2.31.11.1,0,.26-.16.22a53.24,53.24,0,0,0-11.07-2.06A.24.24,0,0,1,624.05,433.42Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '629.606px 434.518px',
          }}
          id="elhpo7vzhstnj"
          className="animable"
        />
        <path
          d="M621.38,435.11c3.73-.78,8.33-.3,11.25,2.3.11.11,0,.27-.16.23a52.59,52.59,0,0,0-11.07-2.06A.24.24,0,0,1,621.38,435.11Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '626.936px 436.208px',
          }}
          id="eljpter333ge"
          className="animable"
        />
        <path
          d="M624.07,426.83c2.7,2,6.17,2.77,9.29,3.85.32.11.57-.2.57-.48a.1.1,0,0,0,.09-.14,20,20,0,0,0-6-8.46c-1.59-1.28-4.35-2.13-5.78-.07S622.5,425.66,624.07,426.83Zm-1-3c-.75-4.1,3.71-2,4.84-1a22.47,22.47,0,0,1,2,2.1,58.57,58.57,0,0,1,3.58,4.87c-1.5-.63-3-1.12-4.56-1.68C627.15,427.4,623.47,426.09,623.06,423.83Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '627.864px 425.496px',
          }}
          id="el8h1b1gu1n7i"
          className="animable"
        />
        <path
          d="M646.41,424.08c-1-2.31-3.84-2.07-5.67-1.16a20,20,0,0,0-7.62,7,.1.1,0,0,0,0,.16c0,.28.12.63.46.59,3.28-.39,6.83-.4,9.89-1.78C645.31,428.07,647.31,426.28,646.41,424.08Zm-7.93,5c-1.61.23-3.21.38-4.81.67a59.29,59.29,0,0,1,4.54-4,21.38,21.38,0,0,1,2.43-1.62c1.33-.73,6.13-1.79,4.52,2.05C644.27,428.29,640.4,428.78,638.48,429.05Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '639.856px 426.47px',
          }}
          id="elnvhpypqwo4"
          className="animable"
        />
        <path
          d="M628.33,431.13l40.73-.75s-11.44-146.9-10-154.69c2.33-12.74.35-27.28-5.78-34.18l-39.69.73S613,253.39,617.21,260c0,0,3.77,2.28,5.59,3.29Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '641.311px 336.32px',
          }}
          id="el0ann3fz9i11u"
          className="animable"
        />
        <path
          d="M629.45,423.9c6.18-.27,4.4-.28,10.58-.49,3-.1,22.89-.75,25.73-.34.06,0,.06.11,0,.12-2.81.54-22.69.84-25.71.88-6.19.09-4.41,0-10.59,0A.09.09,0,0,1,629.45,423.9Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '647.6px 423.523px',
          }}
          id="elpdz0ejp09"
          className="animable"
        />
        <path
          d="M614.7,258.23a76.37,76.37,0,0,1,11.28,6.4.13.13,0,0,1-.13.23c-3.81-2-7.71-4-11.41-6.19A.26.26,0,0,1,614.7,258.23Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '620.199px 261.546px',
          }}
          id="elq3h9obwsmvb"
          className="animable"
        />
        <path
          d="M619.74,257.56a17.42,17.42,0,0,1,.59,2.34,5.05,5.05,0,0,1,.15,1.89c0,.12-.19.11-.25,0a6.23,6.23,0,0,1-.65-1.89c-.2-.72-.42-1.44-.6-2.17A.39.39,0,0,1,619.74,257.56Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '619.743px 259.576px',
          }}
          id="el5a03rikw7xx"
          className="animable"
        />
        <path
          d="M620,253.73c-.12-.66-.24-1.32-.37-2s-.32-1.37-.4-2.06c0-.1-.19-.07-.17,0,.12.66.14,1.35.21,2s.14,1.34.22,2c.15,1.25.38,2.5.65,3.73a4.34,4.34,0,0,1-1.83-.72,3.48,3.48,0,0,1-1-1.89c-.21-.76-.38-1.52-.6-2.27a14,14,0,0,1-.63-2.53c0-.11-.19-.07-.17,0a27.07,27.07,0,0,0,1.11,5.32c.24.69.4,1.38,1,1.75a5.59,5.59,0,0,0,2.23.62c.09.05.22,0,.21-.11C620.4,256.39,620.26,255,620,253.73Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '618.185px 253.653px',
          }}
          id="elbwr49zttvk"
          className="animable"
        />
        <path
          d="M652.59,275.3c.23-2.92.65-5.82.92-8.74a54.25,54.25,0,0,0-1.38-17.45c0-.11.14-.15.16-.05a64.84,64.84,0,0,1,1.84,15.31c-.15,5.15-1,10.24-1,15.4s.52,10.43,1,15.63.87,10.47,1.31,15.7c1.74,21,3.38,42.07,5.05,63.11.47,5.87.91,11.75,1.36,17.62s1.15,20.72,1.48,26.71c0,.52-.74.49-.8,0-1.09-10.54-2-29.91-2.83-40.48s-1.67-20.93-2.51-31.4-1.65-20.93-2.47-31.39c-.41-5.23-.8-10.47-1.2-15.7s-.88-10.43-1-15.65A77.27,77.27,0,0,1,652.59,275.3Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '657.73px 333.961px',
          }}
          id="el2knfb2zfgvq"
          className="animable"
        />
        <path
          d="M638.05,249a.08.08,0,0,1,.15,0,6.78,6.78,0,0,1,.42,2.13,14.77,14.77,0,0,0,.58,2.29,9.34,9.34,0,0,0,2.46,4c2.54,2.29,5.9,3.09,9.14,3.82.12,0,.08.19,0,.2a14.58,14.58,0,0,1-5.63-.77,11.74,11.74,0,0,1-4.39-2.35,8.09,8.09,0,0,1-2.42-4.21,14.69,14.69,0,0,1-.32-2.56c0-.41,0-.83,0-1.24A3.12,3.12,0,0,1,638.05,249Z"
          style={{
            fill: 'rgb(55, 71, 79)',
            transformOrigin: '644.426px 255.207px',
          }}
          id="elowhg7la8up"
          className="animable"
        />
        <path
          d="M622.46,188.05c-16.59,12.31-43.59,13.42-46.6,12-6.37-2.93-15.63-30.44-17.87-40.47-.64-2.9,20.35-2.18,22.45.2,1.92,2.17,6.54,21.18,8.47,21.46a244.51,244.51,0,0,0,28.93-.8C631.76,179,627.37,184.41,622.46,188.05Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '592.594px 179.107px',
          }}
          id="eldwr6iouges"
          className="animable"
        />
        <path
          d="M602,179.82l2.49,19.43s14.77-5.78,21.2-11.71,2.76-8.11-3.66-8.44S602,179.82,602,179.82Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '615.526px 189.143px',
          }}
          id="el1jcu337yfm"
          className="animable"
        />
        <path
          d="M605.19,180.43c0-.13.07-.18.09-.05a133,133,0,0,1,2.1,16.3s-.06,0-.07,0C606.63,194.06,605.41,181.83,605.19,180.43Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '606.285px 188.492px',
          }}
          id="elwqrdbt42yjl"
          className="animable"
        />
        <path
          d="M661.24,254.32c-.14.16-58.9,1.41-59.44.82-.77-.84,8.71-59.77,18-72.63,2.32-3.21,10.66-5.42,14.13-2.17,10.08,9.41,12.2,17.35,14.84,23.82a191.53,191.53,0,0,1,6.45,21.39C658.78,239.39,661.71,253.76,661.24,254.32Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '631.523px 217.02px',
          }}
          id="el0lp2in1fm2vq"
          className="animable"
        />
        <path
          d="M635,180.48s-8.65,19.74-10.07,24.78c-1.95,6.9-4.8,52.38-4.8,52.38l42-2.46s-8-45.47-17.15-61.61C637.8,180.87,635,180.48,635,180.48Z"
          style={{ fill: '#F1923D', transformOrigin: '641.13px 219.06px' }}
          id="el7m5yiovre4y"
          className="animable"
        />
        <path
          d="M621.33,181.43s-4.88,18-5.84,20.72S606,257.67,606,257.67l-5.18-1.93s5.83-36.56,11-55.18S621.33,181.43,621.33,181.43Z"
          style={{ fill: '#F1923D', transformOrigin: '611.075px 219.55px' }}
          id="elujfi93tpdjb"
          className="animable"
        />
        <path
          d="M621.32,239.85l37.62-.08c-.32-1.41-.65-2.89-1-4.42l-36.27.07C621.53,236.92,621.43,238.4,621.32,239.85Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '640.13px 237.6px',
          }}
          id="elfk0namud9x"
          className="animable"
        />
        <path
          d="M603.54,239.88h5.38c.23-1.44.48-2.92.73-4.42h-5.31C604.06,237,603.79,238.46,603.54,239.88Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '606.595px 237.67px',
          }}
          id="elllz6mlccwi"
          className="animable"
        />
        <path
          d="M620.76,247.61l39.84-.08c-.28-1.34-.59-2.82-.93-4.41l-38.59.08C621,244.76,620.86,246.24,620.76,247.61Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '640.68px 245.365px',
          }}
          id="elnrsk7lkg9fq"
          className="animable"
        />
        <path
          d="M602.18,247.65h5.47c.22-1.39.46-2.87.71-4.42l-5.42,0C602.67,244.82,602.41,246.3,602.18,247.65Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '605.27px 245.44px',
          }}
          id="el1oxi40g2kdr"
          className="animable"
        />
        <path
          d="M641.44,193.27c2.24,16.48-.91,39.41-4.66,42.08-10.7,7.61-29.11,11.66-36.94,12.57-4.56.53-6.45-22-2.42-22.69,6.36-1.06,21.58-4.29,23.74-5.26s6.34-16,10.28-26.91C635.72,181.21,640.36,185.29,641.44,193.27Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '618.7px 216.786px',
          }}
          id="el39dxxe3vtxy"
          className="animable"
        />
        <path
          d="M602.46,226a43.2,43.2,0,0,1-9.28-8.44c-3-4-7.09-10.24-9.3-8.69-4.52,3.19,5.61,18.8,5.61,18.8s5.57,6.17,10.34,3.4A5.48,5.48,0,0,0,602.46,226Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '592.596px 220.206px',
          }}
          id="el9cru52x0pkk"
          className="animable"
        />
        <path
          d="M571.16,226.56a3.5,3.5,0,0,0,1.27,3.79,5.19,5.19,0,0,0-1.79,3.05c-.3,2.12,2.9,3.51,2.9,3.51a6.36,6.36,0,0,0-1.38,3.51c-.08,2.06,2.94,3.92,2.94,3.92a5.63,5.63,0,0,0,.74,3.86c2,3,7.5,2.86,11,2.81s13.39-3.49,15.34-4L602,224.56s-15.78-4.22-19.89-3.57C578.67,221.54,572,224,571.16,226.56Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '586.4px 235.975px',
          }}
          id="elbipal3gfuob"
          className="animable"
        />
        <path
          d="M572.58,230c3.54-1.47,7.32-1.8,11.08-2.36.08,0,.1.12,0,.13-3.74.51-7.34,1.75-11,2.41A.1.1,0,0,1,572.58,230Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '578.132px 228.911px',
          }}
          id="elavmcx0r2en"
          className="animable"
        />
        <path
          d="M573.61,236.61a10.15,10.15,0,0,1,2.57-.42c.93-.09,1.85-.17,2.77-.22,1.85-.12,3.71-.2,5.56-.22.08,0,.1.13,0,.13-1.85.12-3.69.27-5.53.47-.91.1-1.83.2-2.75.32a12.71,12.71,0,0,1-2.61.2A.14.14,0,0,1,573.61,236.61Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '579.052px 236.312px',
          }}
          id="elm7gzpmq57sh"
          className="animable"
        />
        <path
          d="M575.18,244.34a6.83,6.83,0,0,1,2.34-.26l2.53-.13c1.69-.09,3.38-.18,5.06-.33.08,0,.1.13,0,.13-1.71.16-3.42.35-5.13.49l-2.52.2a6.58,6.58,0,0,1-2.3,0S575.15,244.35,575.18,244.34Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '580.168px 244.081px',
          }}
          id="eltabhbtxk16"
          className="animable"
        />
        <path
          d="M625.24,208.82l19,5s.17-15.86-2.92-24-6.48-5.59-9.19.24S625.24,208.82,625.24,208.82Z"
          style={{
            fill: 'rgb(235, 235, 235)',
            transformOrigin: '634.741px 199.235px',
          }}
          id="elz5wiy7ykeon"
          className="animable"
        />
        <path
          d="M627,206.1c-.13,0-.15-.12,0-.1a133.75,133.75,0,0,1,15.9,4.16s0,.08,0,.07C640.17,209.88,628.37,206.43,627,206.1Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '634.897px 208.114px',
          }}
          id="el6q7j1qndxbr"
          className="animable"
        />
        <path
          d="M663.78,149.24a22.84,22.84,0,0,1,20,13.4L665.08,162l-4,10.05,3.25,10.3,18.71.67a22.86,22.86,0,1,1-19.28-33.75Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '661.934px 172.108px',
          }}
          id="eluogd0we53k"
          className="animable"
        />
        <path
          d="M530.86,190.22a22.84,22.84,0,0,1-20-13.4l18.7.67,4-10-3.25-10.31-18.71-.67a22.86,22.86,0,1,1,19.27,33.75Z"
          style={{
            fill: 'rgb(69, 90, 100)',
            transformOrigin: '532.706px 167.415px',
          }}
          id="el3koyartuz0v"
          className="animable"
        />
        <g id="elbmsd2gbvju">
          <rect
            x="589.75"
            y="114.24"
            width="20.36"
            height="111.17"
            style={{
              fill: 'rgb(69, 90, 100)',
              transformOrigin: '599.93px 169.825px',
              transform: 'rotate(-87.95deg)',
            }}
            className="animable"
            id="elk2z8s7w88q"
          />
        </g>
        <g id="elm32fqo7gwf8">
          <rect
            x="595.74"
            y="146.93"
            width="8.39"
            height="45.78"
            style={{
              fill: 'rgb(55, 71, 79)',
              transformOrigin: '599.935px 169.82px',
              transform: 'rotate(-87.94deg)',
            }}
            className="animable"
            id="elzs4o5lm3css"
          />
        </g>
        <path
          d="M623.52,188.5c2.43.48,8.21-3.35,10.77-6.92.17-.24.11-3.06.19-6.48.06-2.07.17-4.38.43-6.48l-13.56,1.59a34.22,34.22,0,0,1,.29,8.22,5.29,5.29,0,0,1-.2.85s0,.09,0,.14C620.94,181,619.71,187.76,623.52,188.5Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '627.879px 178.58px',
          }}
          id="elibvsmpn27v8"
          className="animable"
        />
        <path
          d="M621.44,179.28a.43.43,0,0,1,0,.14,8.58,8.58,0,0,0,1.35.24c7.65.76,10.7-8.4,11.39-10.94l-12.8,1.49a34,34,0,0,1,.3,8.22A6.63,6.63,0,0,1,621.44,179.28Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '627.78px 174.212px',
          }}
          id="elyuum15isbr"
          className="animable"
        />
        <path
          d="M637.32,150.85c1.43,4.71-1.06,18.29-4.41,21.32-4.85,4.39-12.79,4.81-16.52-1-3.62-5.66-.06-23.31,3.68-26.06C625.58,141,635.2,143.9,637.32,150.85Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '626.307px 159.359px',
          }}
          id="el5n2upwx88gx"
          className="animable"
        />
        <path
          d="M626,159.22s.06.06,0,.09c-.24.9-.35,2,.41,2.46,0,0,0,.06,0,0C625.46,161.46,625.62,160,626,159.22Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '626.056px 160.508px',
          }}
          id="el4p957a9gsa9"
          className="animable"
        />
        <path
          d="M627,158.45c1.48.22.94,3.17-.43,3S625.76,158.27,627,158.45Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '626.828px 159.95px',
          }}
          id="el64gtw2ojjf"
          className="animable"
        />
        <path
          d="M620.27,158.1s-.08,0-.08.06c-.14.92-.46,2-1.34,2.11a0,0,0,0,0,0,0C619.89,160.36,620.29,159,620.27,158.1Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '619.56px 159.187px',
          }}
          id="el55ns7z8x80a"
          className="animable"
        />
        <path
          d="M619.63,157c-1.45-.38-2.11,2.55-.76,2.9S620.85,157.31,619.63,157Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '619.208px 158.447px',
          }}
          id="eluvu9z4nmqgl"
          className="animable"
        />
        <path
          d="M628.42,157.31a11.33,11.33,0,0,1-1.07-.61c-.41-.24-.76-.39-1-.83a.63.63,0,0,1,.24-.74,1.58,1.58,0,0,1,1.59.13,2.32,2.32,0,0,1,1.13,1.22A.68.68,0,0,1,628.42,157.31Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '627.825px 156.164px',
          }}
          id="eln37h2juwfeq"
          className="animable"
        />
        <path
          d="M618.81,155.65a10.18,10.18,0,0,0,1.23-.09,2,2,0,0,0,1.24-.33.63.63,0,0,0,.1-.77,1.57,1.57,0,0,0-1.49-.57,2.27,2.27,0,0,0-1.55.61A.68.68,0,0,0,618.81,155.65Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '619.809px 154.759px',
          }}
          id="elloil2ye6c1o"
          className="animable"
        />
        <path
          d="M624.49,167.84a1.4,1.4,0,0,1-.87.4,2.39,2.39,0,0,1-.94-.41s-.06,0,0,0a1.28,1.28,0,0,0,1.08.71,1,1,0,0,0,.86-.68C624.6,167.85,624.53,167.82,624.49,167.84Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '623.637px 168.185px',
          }}
          id="elap9uurr5qbe"
          className="animable"
        />
        <path
          d="M624,164.93a3.17,3.17,0,0,1-2.33,1,4,4,0,0,1-1.13-.15l-.21-.06-.2-.07a.24.24,0,0,1-.14-.24h0a.29.29,0,0,1,0-.09h0v-.08c.11-.7.38-1.76.38-1.76-.29.08-1.71.43-1.62.06a47.45,47.45,0,0,1,2.94-9.21.09.09,0,0,1,.16.06c-.6,2.92-1.7,5.72-2.35,8.65a5.44,5.44,0,0,1,1.55-.17c.1,0-.54,2-.57,2.36v0a4.4,4.4,0,0,0,3.41-.41C624,164.79,624.1,164.86,624,164.93Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '621.393px 160.113px',
          }}
          id="el7tn0uudfakm"
          className="animable"
        />
        <path
          d="M621.05,165.5a3.75,3.75,0,0,0,1.27,1.42,1.68,1.68,0,0,0,1,.24c.76,0,.91-.72.88-1.32a4.52,4.52,0,0,0-.17-.94A4.91,4.91,0,0,1,621.05,165.5Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '622.627px 166.032px',
          }}
          id="elygn8sqtpgo"
          className="animable"
        />
        <path
          d="M622.32,166.92a1.68,1.68,0,0,0,1,.24c.76,0,.91-.72.88-1.32A1.75,1.75,0,0,0,622.32,166.92Z"
          style={{
            fill: 'rgb(255, 155, 188)',
            transformOrigin: '623.262px 166.493px',
          }}
          id="elzap9grt3l6"
          className="animable"
        />
        <path
          d="M617.81,148.54c.2-.39.75-1.34,1.28-1.31s1,1.19,2.25,1.64c1.72.66,5-1,7.38-.12s3.62,3.34,3.7,7.72,3.18,8.19,4.41,5.43c0-.1.11-.28.23-.54.74-4.12.94-8.3.26-10.51-2.12-6.95-11.75-9.82-17.25-5.76A8.57,8.57,0,0,0,617.81,148.54Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '627.773px 153.031px',
          }}
          id="elob4v4xn3zv8"
          className="animable"
        />
        <path
          d="M634.41,161.92s3.16-4.33,5.13-3.19-.47,7.46-2.83,8.28a2.37,2.37,0,0,1-3.16-1.34Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '636.906px 162.872px',
          }}
          id="eluwub6k0sn9l"
          className="animable"
        />
        <path
          d="M638.8,160.53s0,.05,0,.06a6,6,0,0,0-3.19,3.7,1.31,1.31,0,0,1,2-.32s0,.1,0,.09a1.47,1.47,0,0,0-1.62.47,7.49,7.49,0,0,0-.8,1.29c-.08.15-.35.06-.29-.1l0,0C634.86,163.58,636.55,160.69,638.8,160.53Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '636.846px 163.213px',
          }}
          id="el8kd97jzyfer"
          className="animable"
        />
        <path
          d="M615.84,150.37s4-13.07,15.71-9.56,7.86,16.63,7.86,16.63Z"
          style={{ fill: '#F1923D', transformOrigin: '627.928px 148.826px' }}
          id="eljkq3sucg31m"
          className="animable"
        />
        <g id="eld7s5g39gsns">
          <rect
            x="614.4"
            y="152.94"
            width="26.44"
            height="1.94"
            style={{
              fill: '#F1923D',
              transformOrigin: '627.62px 153.91px',
              transform: 'rotate(16.7deg)',
            }}
            className="animable"
            id="elkwrpitt0rzi"
          />
        </g>
        <polygon
          points="627.71 152.92 622.44 151.34 627.53 138.87 632.8 140.45 627.71 152.92"
          style={{ fill: '#F1923D', transformOrigin: '627.62px 145.895px' }}
          id="eljrg8thge5e"
          className="animable"
        />
        <g id="elonka2pa1rph">
          <polygon
            points="627.71 152.92 622.44 151.34 627.53 138.87 632.8 140.45 627.71 152.92"
            style={{ opacity: '0.1', transformOrigin: '627.62px 145.895px' }}
            className="animable"
            id="elf95bb5no73d"
          />
        </g>
        <path
          d="M557.08,172.09c2.55,1.72,3.12-2.28,3.12-2.28s.57,6,3.9,6.71,3.72-4.14,3.76-4.48c0,.31.7,5.77,4.12,6.19s3.23-4.54,3.21-4.86c.13.29,1.84,4.06,4.51,3.79,4.58-.48.6-11.67,2.16-15.06l6.78-1.55a9.27,9.27,0,0,1-4.53-2.67c-1.82-1.84-3-3.07-4.55-3.58a3.38,3.38,0,0,0-3.87,1.15s-.87-3.16-3.55-3.57c-1.38-.21-2.94,1.81-2.94,1.81A4,4,0,0,0,566,151c-1.92-.18-3.19,1.88-3.19,1.88s-1.88-2-3.9-1.59C554.83,152.16,551.6,168.39,557.08,172.09Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '571.407px 164.622px',
          }}
          id="eln7b4wfn6bob"
          className="animable"
        />
        <path
          d="M583.36,161.5l5.14-.94.54-.24c6-2.92,7.92-11.26,3.19-10.5-3.6.58-6.08,6.25-9.85,6.31h0Z"
          style={{
            fill: 'rgb(211, 118, 106)',
            transformOrigin: '588.631px 155.636px',
          }}
          id="el0usymzjnmufa"
          className="animable"
        />
        <path
          d="M562.56,153.14a0,0,0,1,0-.08,0A28.73,28.73,0,0,0,560.15,170c0,.18.34.21.34,0A59,59,0,0,1,562.56,153.14Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '561.164px 161.643px',
          }}
          id="elfb05zacw5g"
          className="animable"
        />
        <path
          d="M569.19,153.6c0-.06-.09-.09-.12,0a27,27,0,0,0-1.4,18.61c.07.22.35.34.29.09C567,168,567.7,158.74,569.19,153.6Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '567.912px 162.991px',
          }}
          id="el3089whhcom3"
          className="animable"
        />
        <path
          d="M575.48,173.76c-1.67-6-1.24-12,.09-18.1,0-.08-.09-.1-.13,0-1.46,2.8-1.9,6.76-2,9.87a16.57,16.57,0,0,0,1.81,8.33C575.32,173.92,575.52,173.89,575.48,173.76Z"
          style={{
            fill: 'rgb(38, 50, 56)',
            transformOrigin: '574.496px 164.741px',
          }}
          id="eln9s4zc9a3ys"
          className="animable"
        />
      </g>
      <defs>
        {' '}
        <filter id="active" height="200%">
          {' '}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />{' '}
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />{' '}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
          <feMerge>
            {' '}
            <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
          </feMerge>{' '}
        </filter>{' '}
        <filter id="hover" height="200%">
          {' '}
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />{' '}
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />{' '}
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />{' '}
          <feMerge>
            {' '}
            <feMergeNode in="OUTLINE" /> <feMergeNode in="SourceGraphic" />{' '}
          </feMerge>{' '}
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          />{' '}
        </filter>
      </defs>
    </svg>
  );
};

export default UnderConstructionImage;
